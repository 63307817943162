import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-navmenu',
    templateUrl: './navmenu.component.html',
    styleUrls: ['./navmenu.component.scss']
})
export class NavmenuComponent implements OnInit {
    constructor(public dataservice: DataserviceService) {}

    ngOnInit() {

    }
    logout() {
      this.dataservice.logout();
    }
}
