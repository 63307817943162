import { PasswordrecoverComponent } from './../passwordrecover/passwordrecover.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ISysUser } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { UserlistdialogComponent } from '../userlistdialog/userlistdialog.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    user: ISysUser = { firstName: '', password: '', lastName: '' } as ISysUser;
    recover = false;
    message = '';
    constructor(public dataservice: DataserviceService, public dialog: MatDialog) {}

    ngOnInit() {}

    login() {
        console.log('User: ', this.user);
        this.dataservice.login(this.user, (error) => {
            console.log('error', error);
            if (error.status === 401) {
                this.message = 'Nome utente o password errati';
                this.recover = true;
            }
        });
    }

    recoverPass() {
        const dialogRef = this.dialog.open(PasswordrecoverComponent, {});
        dialogRef.afterClosed().subscribe((result) => {});
    }
}
