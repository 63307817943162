<div class="pagecontainer">
    <div class="tabcontainer">
        <h2 class="tabtitle">HOME</h2>
        <div class="thumbcontainer">
            <app-menutab *ngIf="dataservice.currentUser?.apartments.length > 0" [badge]="dataservice.currentUser?.apartments.length" caption="Appartamenti" link="/apartments/my" color="white" bkcolor="darkorchid" icon="house"> </app-menutab>
            <app-menutab *ngIf="dataservice.currentUser?.buildings.length > 0" [badge]="dataservice.currentUser?.buildings.length" caption="Stabili" link="/buildings" color="white" bkcolor="wheat" icon="apartment"></app-menutab>
            <app-menutab caption="Profilo" link="/profile" color="white" bkcolor="aquamarine" icon="account_box"> </app-menutab>
            <app-menutab caption="Aiuto" link="/help" color="white" bkcolor="salmon" icon="help"> </app-menutab>
        </div>
    </div>
    <div class="tabcontainer" *ngIf="dataservice.currentUser?.role > 2">
        <h2 class="tabtitle">Contabilizzatori</h2>
        <div class="thumbcontainer">
            <app-menutab [badge]="stats.total" caption="Totali" link="#" color="white" bkcolor="lightblue"> </app-menutab>
            <app-menutab [badge]="stats.errors" caption="Con errori" link="#" color="white" bkcolor="lightred"> </app-menutab>
            <app-menutab [badge]="stats.updated" caption="Aggiornati" link="#" color="white" bkcolor="lightgreen"> </app-menutab>
            <app-menutab [badge]="stats.total-stats.updated" caption="NON aggiornati" link="#" color="white" bkcolor="orange"> </app-menutab>
        </div>
    </div>
</div>
