<div class="tabcontainer">
    <h2 class="tabtitle">Concentratori Contabilizzatori calore</h2>

    <table mat-table [dataSource]="items" class="mat-elevation-z8">
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Num.</th>
            <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="mac">
            <th mat-header-cell *matHeaderCellDef>MAC</th>
            <td mat-cell *matCellDef="let element">{{ getMac(element.mac) }}</td>
        </ng-container>

        <ng-container matColumnDef="imei">
            <th mat-header-cell *matHeaderCellDef>IMEI</th>
            <td mat-cell *matCellDef="let element">
                {{ element.imei }}
            </td>
        </ng-container>
        <ng-container matColumnDef="imsi">
            <th mat-header-cell *matHeaderCellDef>IMSI</th>
            <td mat-cell *matCellDef="let element">
                {{ element.imsi }}
            </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>Descrizione</th>
            <td mat-cell *matCellDef="let element">
                {{ element.description }}
            </td>
        </ng-container>
        <ng-container matColumnDef="building">
            <th mat-header-cell *matHeaderCellDef>Struttura</th>
            <td mat-cell *matCellDef="let element">
                {{ element.building?.name }}
            </td>
        </ng-container>

        <ng-container matColumnDef="batt">
            <th mat-header-cell *matHeaderCellDef>Batteria</th>
            <td mat-cell *matCellDef="let element">
                {{ dataservice.getBattVoltage(element) }}V <mat-icon [class]="getBattClass(element)">{{ getBattIcon(element) }}</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="last">
            <th mat-header-cell *matHeaderCellDef>Ultimo aggiornamento</th>
            <td mat-cell *matCellDef="let element">
                {{ element.lastUpdate | date : 'dd MMM yyyy - HH:mm:ss' }}
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="edit(element)"><mat-icon>edit</mat-icon></button>
                <a mat-icon-button href="/devices/concentrator/{{ element.concentratorId }}"><mat-icon [matBadge]="element.totalLen">dock</mat-icon></a>
                <a mat-icon-button href="/concentrator/logs/{{ element.mac }}"><mat-icon>list_alt</mat-icon></a>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="select(row)"></tr>

        <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
    </table>
</div>
