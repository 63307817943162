<div class="tabcontainer">
    <div class="sectiontitleicon">
        <app-progressspinner *ngIf="progress"></app-progressspinner>
        <img src="/assets/meter.png" />
        <h2 class="tabtitle">Gestione contabilizzatori calore</h2>
        <div *ngIf="currentItem && currentReq === 'apartment'" class="breadcrumbs">
            <a href="/buildings/">Stabili</a>&nbsp;&gt;&gt;&nbsp; <a href="/apartments/{{ getItemId() }}">{{ getItemName() }}</a
            >&nbsp;&gt;&gt;&nbsp; <span>{{ currentItem.name }}</span>
        </div>
        <div *ngIf="currentItem && currentReq === 'building'" class="breadcrumbs">
            <a href="/buildings/">Stabili</a>&nbsp;&gt;&gt;&nbsp; <a href="/building/{{ getItemId() }}">{{ getItemName() }}</a
            >&nbsp;&gt;&gt;&nbsp; <span>{{ currentItem.name }}</span>
        </div>
    </div>
    <button mat-raised-button *ngIf="currentReq === 'concentrator'" (click)="clear()">Clear!</button>
    <div class="summary">
        <mat-form-field class="field field__find">
            <mat-label>Cerca</mat-label>
            <input matInput placeholder="Cerca" [(ngModel)]="toFind" (ngModelChange)="find()" />
        </mat-form-field>
        <button mat-icon-button (click)="remFind()">
            <mat-icon class="mat-18">delete_forever</mat-icon>
        </button>
        <div class="separator"></div>
        <div class="summary__item"  (click)="sel('all')">
            <span class="bullet bullet_blue"></span>
            <span>Totali {{ devices.length }}</span>
        </div>
        <div class="summary__item" (click)="sel('al')" [ngClass]="{ 'summary__item--sel': fAlive }">
            <span class="bullet bullet_green"></span>
            <span>Operativi {{ alive }}</span>
        </div>
        <div class="summary__item" (click)="sel('ol')" [ngClass]="{ 'summary__item--sel': fOffline }">
            <span class="bullet bullet_black"></span>
            <span>Offline {{ dead }}</span>
        </div>

        <div class="summary__item" (click)="sel('nu')" [ngClass]="{ 'summary__item--sel': fNotUpdated }">
            <span class="bullet bullet_orange"></span>
            <span>Non aggiornati {{ old }}</span>
        </div>

        <div class="summary__item" (click)="sel('er')" [ngClass]="{ 'summary__item--sel': fError }">
            <span class="bullet bullet_red"></span>
            <span>Con errori {{ berror }}</span>
        </div>
        <div class="summary__item" (click)="sel('der')" [ngClass]="{ 'summary__item--sel': fdError }">
            <span class="bullet bullet_darkgold"></span>
            <span>Dati non validi {{ derr }}</span>
        </div>
        <div class="summary__item" (click)="sel('or')" [ngClass]="{ 'summary__item--sel': forf }">
            <span class="bullet bullet_violet"></span>
            <span>Orfani {{ dorf }}</span>
        </div>
        <div class="summary__sep"></div>
        <mat-button-toggle-group class="summary__btn" name="listDetail" aria-label="Dettagli lista" [(ngModel)]="dataSpecs">
            <mat-button-toggle value="0">Dati</mat-button-toggle>
            <mat-button-toggle value="1">Dettagli</mat-button-toggle>
            <mat-button-toggle value="2">Letture</mat-button-toggle>
        </mat-button-toggle-group>
    </div>

    <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" (matSortChange)="SortChange($event)">
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Num.</th>
            <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="devid">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Codice</th>
            <td mat-cell *matCellDef="let element" [ngClass]="{ 'serial--err': element.dataError }">{{ dataservice.getSerial(element) }}</td>
        </ng-container>

        <ng-container matColumnDef="cname">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="location">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Ubicazione</th>
            <td mat-cell *matCellDef="let element">{{ getLocation(element) }}</td>
        </ng-container>

        <ng-container matColumnDef="medium">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Medium</th>
            <td mat-cell *matCellDef="let element">
                {{ dataservice.getMedium(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef>Dim. L/A/P</th>
            <td mat-cell *matCellDef="let element">
                {{ getRadiatorSize(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="rtype">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Tipo</th>
            <td mat-cell *matCellDef="let element">
                {{ getRadiatorType(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="valk">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>K val.</th>
            <td mat-cell *matCellDef="let element">
                <div [innerHTML]="getKVal(element)"></div>
            </td>
        </ng-container>

        <ng-container matColumnDef="ksource">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Dati in chiaro</th>
            <td mat-cell *matCellDef="let element">
                {{ getKsource(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="devprod">
            <th mat-header-cell *matHeaderCellDef>Prod.</th>
            <td mat-cell *matCellDef="let element">
                {{ dataservice.getManu(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Impianto</th>
            <td mat-cell *matCellDef="let element">
                {{ element.type }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Stato</th>
            <td mat-cell *matCellDef="let element">
                <div class="bulletstatus statuscontainer">
                    <span class="bullet" [style]="getBulletStyle(element)"></span>
                    <mat-icon aria-hidden="false" [style]="getIconStyle(element)">{{ getStatusIcon(element) }}</mat-icon>
                    <span *ngIf="element.status !== 0">{{ element.status }}</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="errdate">
            <th mat-header-cell *matHeaderCellDef>Data errore</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="dateValid(element.errorDate)">{{ element.errorDate | date : 'dd MMM yyy' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="energy">
            <th mat-header-cell *matHeaderCellDef>Cum. Energy</th>
            <td mat-cell *matCellDef="let element">
                {{ element.cumulativeEnergy }}
            </td>
        </ng-container>
        <ng-container matColumnDef="dayval">
            <th mat-header-cell *matHeaderCellDef>Day Value</th>
            <td mat-cell *matCellDef="let element">
                {{ element.dayValue }}
            </td>
        </ng-container>
        <ng-container matColumnDef="stat">
            <th mat-header-cell *matHeaderCellDef>Stat Value</th>
            <td mat-cell *matCellDef="let element">
                {{ element.statData }}
            </td>
        </ng-container>

        <ng-container matColumnDef="readout">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Ultima lettura</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="dateValid(element.readoutTime)">{{ element.readoutTime | date : 'dd MMM yyy HH:mm' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="concentrators">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Concentratori</th>
            <td mat-cell *matCellDef="let element">
                <span class="tdconc" *ngFor="let item of element.concentrators">{{ item.description }}</span>
            </td>
        </ng-container>

        <ng-container matColumnDef="update">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Ultimo aggiornamento</th>
            <td mat-cell *matCellDef="let element">
                <div [class]="getDeviceClass(element)" *ngIf="dateValid(element.lastUpdateTime)">{{ element.lastUpdateTime | date : 'dd MMM yyy HH:mm' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="statdat">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Dato statistico</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="dateValid(element.statisticalDate)">{{ element.statisticalDate | date : 'MMM yyy' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="getDisplayedColumns()"></tr>
        <tr mat-row *matRowDef="let row; columns: getDisplayedColumns()" (click)="select(row)" class="matlinkrow"></tr>

        <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
    </table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons aria-label="pagina"> </mat-paginator>
    <button mat-raised-button *ngIf="dataservice.getPermission(true, 3) && currentReq === 'apartment'" (click)="addMeter()">Aggiungi/rimuovi contabilizzatori</button>
</div>
