import { IApartment, IMeterDevice } from './../../interfaces';
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-adddevices',
    templateUrl: './adddevices.component.html',
    styleUrls: ['./adddevices.component.scss']
})
export class AdddevicesComponent implements OnInit, AfterViewInit {
    item: IApartment;
    // items: IMeterDevice[];
    items: MatTableDataSource<IMeterDevice>;
    addedItems: IMeterDevice[] = [];
    displayedColumns: string[] = ['position', 'devid', 'devprod', 'action'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    constructor(public dialog: MatDialog, public dataservice: DataserviceService, public dialogRef: MatDialogRef<AdddevicesComponent>, @Inject(MAT_DIALOG_DATA) public data: IApartment) {
        this.item = { ...data };
        for (const item of data.meterDevices) {
            this.addedItems.push(item);
        }
    }
    ngAfterViewInit() {}
    onSearchChange(tofind: string) {
        if (tofind.trim().length > 2) {
            this.dataservice.findDevice(tofind.trim()).subscribe((data) => {
                this.items = new MatTableDataSource<IMeterDevice>(data as IMeterDevice[]);
                this.items.paginator = this.paginator;
            });
        }
    }
    ngOnInit(): void {}


    getIndex(device: IMeterDevice) {
        return this.addedItems.findIndex((x) => x.meterDeviceId === device.meterDeviceId);
    }

    isSelected(device: IMeterDevice) {
        return this.addedItems.findIndex((x) => x.meterDeviceId === device.meterDeviceId) !== -1;
    }

    addDevice(device: IMeterDevice) {
        const found = this.addedItems.findIndex((x) => x.meterDeviceId === device.meterDeviceId);
        if (found === -1) {
            this.addedItems.push(device);
        } else {
            this.addedItems.splice(found, 1);
        }
    }


    saveData() {
        this.item.meterDevices = this.addedItems;
        console.log('Saving data', this.item);
        this.dataservice.saveApartment(this.item).subscribe((data) => {
            this.dataservice.toastr.success('Salvataggio eseguito');
        });
        this.dialogRef.close();
    }

    cancel() {
        this.dialogRef.close();
    }
}
