import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-graphbar',
    templateUrl: './graphbar.component.html',
    styleUrls: ['./graphbar.component.scss']
})
export class GraphbarComponent implements OnInit {
    @Input() label: string;
    @Input() color: string;
    @Input() textcolor: string;
    @Input() value: number;
    @Input() max: number;
    @Input() direction: string;

    constructor() { }

    ngOnInit(): void {
        if (undefined === this.direction) {
            this.direction = 'horizontal';
        }
    }

    getStyle() {
        const right = (100 - (100 * this.value) / this.max).toFixed(0) + '%';
        return { right, backgroundColor: this.color, color: this.textcolor };
    }
}
