import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { IApartment, IBuilding, ISortParams } from 'src/interfaces';
import { ApartmentComponent } from '../apartment/apartment.component';
import { DataserviceService } from '../dataservice.service';
import { UserlistdialogComponent } from '../userlistdialog/userlistdialog.component';

@Component({
    selector: 'app-manageapartment',
    templateUrl: './manageapartment.component.html',
    styleUrls: ['./manageapartment.component.scss']
})
export class ManageapartmentComponent implements OnInit {
    dataSource: MatTableDataSource<IApartment>;

    allItems: IApartment[] = [];
    sortedData: IApartment[] = [];
    currentId: number;
    currentBuilding: IBuilding;
    key: string;
    displayedColumns: string[] = ['name', 'stair', 'floor', 'phone', 'email', 'code', 'note', 'action'];
    progress = false;
    toFind = '';
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dialog: MatDialog, private route: ActivatedRoute, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            this.progress = true;
            console.log('Pmap; ', map.get('id'));
            this.key = map.get('id');
            if (this.key === 'my') {
                this.allItems = this.dataservice.currentUser.apartments;
                this.sortedData = this.allItems.slice();

                this.dataSource = new MatTableDataSource<IApartment>(this.sortedData);
                this.dataSource.paginator = this.paginator;
            } else {
                this.currentId = parseInt(this.key, 10);
                this.dataservice.getBuilding(this.currentId).subscribe((building) => {
                    this.currentBuilding = building as IBuilding;
                    this.dataservice.getApartmets(this.currentId).subscribe((data) => {
                        this.progress = false;

                        this.allItems = data as IApartment[];
                        this.sortedData = this.allItems.slice();

                        this.dataSource = new MatTableDataSource<IApartment>(this.sortedData);
                        this.dataSource.paginator = this.paginator;
                    });
                });
            }
            this.ngOnInit();
        });
    }
    /*
    find() {
        console.log('Tofind', this.toFind);
        if (this.toFind.length > 3) {
            const items = this.allItems.filter((x) => x.name.toLowerCase().indexOf(this.toFind.toLowerCase()) !== -1);
            this.dataSource = new MatTableDataSource<IApartment>(items);
            this.dataSource.paginator = this.paginator;
        } else {
            this.dataSource = new MatTableDataSource<IApartment>(this.allItems);
            this.dataSource.paginator = this.paginator;
        }
    }*/

    getFilteredDevices() {
        const sorted = this.sortedData.filter((x) => this.applyFilter(x));
        return sorted;
    }

    applyFilter(x: IApartment): boolean {
        if (this.toFind.length > 2) {
            if (x.name.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    SortChange(event) {
        console.log(event);
        const data = this.allItems.slice();
        const sortParams = event as ISortParams;
        sortParams.enabled = sortParams.direction !== '';
        if (sortParams.enabled) {
            this.sortedData = data.sort((a, b) => {
                switch (sortParams.active) {
                    case 'name':
                        return this.dataservice.compare(a.name, b.name, sortParams.direction);
                    case 'stair':
                        return this.dataservice.compare(a.staircase, b.staircase, sortParams.direction);
                    case 'floor':
                        return this.dataservice.compare(a.floor, b.floor, sortParams.direction);
                    case 'phone':
                        return this.dataservice.compare(a.ownerphone || b.ownerphone2, b.ownerphone || b.ownerphone2, sortParams.direction);

                    default:
                        return 0;
                }
            });
        } else {
            this.sortedData = this.allItems.slice();
        }
        this.find();
    }

    find() {
        this.dataSource = new MatTableDataSource<IApartment>(this.getFilteredDevices());
        this.dataSource.paginator = this.paginator;
    }

    ngOnInit(): void {}

    showuser(item: IApartment) {
        const dialogRef = this.dialog.open(UserlistdialogComponent, {
            data: { apartment: item }
        });

        dialogRef.afterClosed().subscribe((result) => {});
    }

    select(row: IApartment) {
        const dialogRef = this.dialog.open(ApartmentComponent, {
            data: { ...row }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result);
                const item = result as IApartment;
                item.building = { buildingId: this.currentId } as IBuilding;
                this.dataservice.saveApartment(item).subscribe((x) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.ngOnInit();
                });
            }
        });
    }

    addNew() {
        const dialogRef = this.dialog.open(ApartmentComponent, {
            data: {} as IApartment
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result);
                const item = result as IApartment;
                item.building = { buildingId: this.currentId } as IBuilding;
                this.dataservice.addApartment(item).subscribe((x) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.ngOnInit();
                });
            }
        });
    }
}
