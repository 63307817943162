<div class="mcontainer">
    <h3>{{ title }}</h3>

    <div class="legend" *ngIf="label !== undefined">
        <div class="label1">
            Elemento: <b>{{ label }}</b>
        </div>
        <div class="label1">
            Consumo: <b>{{ value }} €</b>
        </div>
    </div>
    <div id="pieChart" class="chartp" ></div>
</div>
