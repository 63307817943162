
<h1>Stabili</h1>
    <table mat-table [dataSource]="items" class="mat-elevation-z8">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let element">{{element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef>Indirizzo</th>
            <td mat-cell *matCellDef="let element">
                {{ getAddress(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef>Note</th>
            <td mat-cell *matCellDef="let element">
                {{ element.type }}
            </td>
        </ng-container>


        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">

            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="select(row)"></tr>

        <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
    </table>


