import { AfterViewInit, Component, Renderer2 } from '@angular/core';
import { DataserviceService } from './dataservice.service';
import { LoaderService } from './loader.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
    title = 'ipnetgreen-frontend';
    constructor(private loaderService: LoaderService, private renderer: Renderer2) {}

    ngAfterViewInit() {
        this.loaderService.httpProgress().subscribe((status: boolean) => {
            console.log('xxxxxxxxxxxxxxxxxxxxxxx');
            if (status) {
                this.renderer.addClass(document.body, 'cursor-loader');
            } else {
                this.renderer.removeClass(document.body, 'cursor-loader');
            }
        });
    }
}
