import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-menutab',
    templateUrl: './menutab.component.html',
    styleUrls: ['./menutab.component.scss']
})
export class MenutabComponent implements OnInit {
    @Input() link: string;
    @Input() caption: string;
    @Input() bkcolor: string;
    @Input() color: string;
    @Input() icon: string;
    @Input() badge: number;
    @Input() subcaption: string;
    constructor() {}

    ngOnInit(): void {}

    getStyle() {
        return {
            color: this.color,
            backgroundColor: this.bkcolor
        };
    }
}
