import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eventlogs',
  templateUrl: './eventlogs.component.html',
  styleUrls: ['./eventlogs.component.scss']
})
export class EventlogsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
