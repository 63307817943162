import { HeatdetailComponent } from './../heatdetail/heatdetail.component';
import { Component, OnInit } from '@angular/core';
import { IMeterDevice } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
    displayedColumns: string[] = ['position', 'devid', 'devprod', 'status', 'errdate', 'energy', 'dayval', 'stat', 'readout', 'statdat', 'action'];
    Arr = Array;
    devices: IMeterDevice[] = [];
    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {}

    ngOnInit() {
        this.dataservice.getMeters(1, '').subscribe((data) => {
            this.devices = data as IMeterDevice[];
        });
    }

    select(item) {
        this.dataservice.getMeterDetail(item.meterDeviceId).subscribe((data) => {
            const dialogRef = this.dialog.open(HeatdetailComponent, {
                data: data as IMeterDevice
            });

            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    console.log(result);
                    this.dataservice.saveMeter(result as IMeterDevice).subscribe((x) => {
                        this.dataservice.toastr.success('Salvataggio eseguito');
                    });
                }
            });
        });
    }



    getIconStyle(element: IMeterDevice) {
        if (element.status === 0) {
            return { color: 'green' };
        }
        if (element.status === 120) {
            return { color: 'orange' };
        }
        return { color: 'red' };
    }

    getStatusIcon(element: IMeterDevice) {
        if (element.status === 0) {
            return '';
        }
        if (element.status === 120) {
            return 'plumbing';
        }
        return 'report_gmailerrorred';
    }

    dateValid(data: Date) {
        return new Date(data).getFullYear() !== 1;
    }

    getFilteredDevices() {
        return this.devices.filter((x) => x.serial.substring(8, 12) === '4493');
    }
}
