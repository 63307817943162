import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IApartment } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';
import { MyErrorStateMatcher } from '../profile/profile.component';

@Component({
    selector: 'app-passwordrecover',
    templateUrl: './passwordrecover.component.html',
    styleUrls: ['./passwordrecover.component.scss']
})
export class PasswordrecoverComponent {
    email = '';
    phone = '';
    matcher = new MyErrorStateMatcher();
    emailFormControl = new FormControl('', [Validators.required, Validators.email]);
    phoneFormControl = new FormControl('', [Validators.required, Validators.pattern('[- +()0-9]+')]);
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<PasswordrecoverComponent>, @Inject(MAT_DIALOG_DATA) public data: IApartment) {}

    validate() {}

    saveData() {}

    close() {
        this.dialogRef.close();
    }
}
