import { EditlabelComponent } from './editlabel/editlabel.component';
import { IBuilding, IConcentrator, IDictionary, ISysUser } from './../interfaces';
import { Injectable, OnInit, EventEmitter, Output } from '@angular/core';
import { IUserProfile, IMeterDevice, IApartment } from 'src/interfaces';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { ConfirmdialogComponent } from './confirmdialog/confirmdialog.component';
import { interval } from 'rxjs';
import docTypes from './doctypes.json';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
    providedIn: 'root'
})
export class DataserviceService {
    connected = false;
    socket: any;
    token: string;
    public currentUser: ISysUser;
    userLoggedIn: boolean;
    random: number;
    currentRoot: string;
    currentAera = 0;
    currentApartament: IApartment;
    currentBuilding: IBuilding;
    dictionary: IDictionary[];
    colors = ['tomato', 'lightblue', 'burlywood', 'brown', 'darkcyan', 'magenta', 'plum', 'grey', 'cornflowerblue', 'gold'];
    monthNamesIt = ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'];
    constructor(private http: HttpClient, public dialog: MatDialog, private router: Router, public toastr: ToastrService) {
        const token = localStorage.getItem('token');
        this.token = token;
        this.whoAmI();
    }

    compare(a: number | string, b: number | string, isAsc: string) {
        return (a < b ? -1 : 1) * (isAsc === 'asc' ? 1 : -1);
    }

    compareDate(a: Date, b: Date, isAsc: string) {
        return (a.getTime() < b.getTime() ? -1 : 1) * (isAsc === 'asc' ? 1 : -1);
    }

    confirm(title: string, buttons: string[]) {
        return new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(ConfirmdialogComponent, {
                data: { text: title, buttons }
            });

            dialogRef.afterClosed().subscribe((result) => {
                console.log(result);
                resolve(result);
            });
        });
    }

    startWebsocket() {
        if (!this.connected) {
            console.log('Start webSocket');
            this.socket.subscribe(
                (message) => {
                    this.connected = true;
                },
                (error) => {
                    console.error(error); // handle errors
                    this.connected = false;
                }
            );
        } else {
            console.log('websocket is connected');
        }
    }

    strToBuffer(str) {
        const arrayBuffer = new ArrayBuffer(str.length * 1);
        const newUint = new Uint8Array(arrayBuffer);
        newUint.forEach((_, i) => {
            newUint[i] = str.charCodeAt(i);
        });
        return newUint;
    }

    decodeUpdates(message: any) {}

    newGuid() {
        return 'xxxxxxxx-xxxx-'.replace(/[xy]/g, (c) => {
            // tslint:disable-next-line:no-bitwise
            const r = (Math.random() * 16) | 0;
            // tslint:disable-next-line:no-bitwise
            const v = c === 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    getUserHeader() {
        if (this.token) {
            const token = this.token;

            const headersObject = new HttpHeaders({
                Authorization: 'Bearer ' + token
            });

            const httpOptions = {
                headers: headersObject
            };
            return httpOptions;
        } else {
            return {};
        }
    }

    getHeader() {
        if (this.token) {
            const token = this.token;

            const headersObject = new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token
            });

            const httpOptions = {
                headers: headersObject
            };
            return httpOptions;
        } else {
            return {};
        }
    }

    getTextHeader() {
        if (this.token) {
            const token = this.token;

            const headersObject = new HttpHeaders({
                'Content-Type': 'text/plain',
                Authorization: 'Bearer ' + token
            });

            const httpOptions = {
                headers: headersObject,
                responseType: 'text'
            };
            return httpOptions;
        } else {
            return {};
        }
    }

    addUser(selectedUser: ISysUser) {
        return this.http.post('/api/users', selectedUser, this.getHeader());
    }

    login(user: ISysUser, errorCb) {
        this.http.post('/api/users/login', user).subscribe(
            (result) => {
                console.log('result: ', result);
                this.token = (result as any).token;
                localStorage.setItem('token', this.token);
                this.whoAmI();

                this.router.navigate(['/']);
            },
            (error) => {
                console.error(error);
                console.log(error);
                this.currentUser = null;
                this.userLoggedIn = false;
                this.toastr.error('error', 'Login error');
                errorCb(error);
            }
        );
    }

    logout() {
        this.token = undefined;
        localStorage.setItem('token', this.token);
        this.router.navigate(['/login']);
        this.currentUser = undefined;
    }

    goHome() {
        this.router.navigate(['/']);
    }

    whoAmI() {
        this.http.get('/api/users/whoami', this.getHeader()).subscribe(
            (user) => {
                this.currentUser = user as ISysUser;
                this.toastr.success('Login', 'Benvenuto ' + this.currentUser.firstName);
                const seconds = interval(5000);
            },
            (error) => {
                this.currentUser = undefined;
                this.testLogged();
            }
        );
    }

    async whoAmIBlocking() {
        this.currentUser = (await this.http.get('/api/users/whoami', this.getHeader()).toPromise()) as ISysUser;
    }

    getPermission(logged: boolean, role: number) {
        if (logged === true) {
            if (this.currentUser === undefined || this.currentUser === null) {
                return false;
            }

            return this.currentUser.role >= role;
        }
        if (this.currentUser === undefined) {
            return true;
        }
        return false;
    }

    getAuth(logged: boolean, role: number, area: number) {
        if (!this.getPermission(logged, role)) {
            return { display: 'none' };
        }
        return {};
    }

    testLogged() {
        console.log('VVVVVV');
        if (undefined === this.currentUser && this.router.url !== '/login' && this.router.url !== '/signup' && this.router.url !== '/verify') {
            this.router.navigate(['/login']);
        }
    }
    setUtil(command: string) {
        return this.http.get('/api/inexp/' + command, this.getHeader());
    }
    getUsers(filter: string, id: number) {
        if (filter) {
            return this.http.get('/api/users/' + filter + '/' + id, this.getHeader());
        }
        return this.http.get('/api/users', this.getHeader());
    }

    deleteUser(user: ISysUser) {
        return this.http.delete('/api/users/' + user.sysUserId, this.getHeader());
    }

    verifyUser(code: string) {
        return this.http.get('/api/users/verify/' + code);
    }

    saveMeterName(device: IMeterDevice) {
        return this.http.put('/api/meters/name/' + device.meterDeviceId, device, this.getHeader());
    }

    findFilterDevices(serial: string) {
        return this.http.get('/api/meters/search/' + serial, this.getHeader());
    }

    getBuildings() {
        return this.http.get('/api/buildings', this.getHeader());
    }
    getBuilding(id: number) {
        return this.http.get('/api/buildings/' + id, this.getHeader());
    }

    getConcentrators() {
        return this.http.get('/api/concentrators', this.getHeader());
    }

    getConcentratorLogs(currentId: string) {
        console.log('get logs');
        return this.http.get('/api/concentrators/logs/' + currentId, this.getHeader());
    }

    updateProfile(profile: IUserProfile) {
        this.http.post('/api/users/profile', profile, this.getHeader()).subscribe(
            () => {
                this.toastr.success('Aggiornamento utente eseguito');
            },
            () => {
                this.toastr.error("Errore durante l'aggiornamento dell'utente");
            }
        );
    }

    addBuildings(building: IBuilding) {
        return this.http.post('/api/buildings', building, this.getHeader());
    }

    saveBuildings(building: IBuilding) {
        return this.http.put('/api/buildings/' + building.buildingId, building, this.getHeader());
    }

    getRole(role: number): string {
        switch (role) {
            case 0:
                return 'Guest';
            case 1:
                return 'Utente';
            case 2:
                return 'Amminiatratore';
            case 3:
                return 'System administrator';
            case 4:
                return 'God';
        }
    }

    getRandom(randLen: number) {
        const strin = 'qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM1234567890X';
        let strout = '';
        for (let i = 0; i < randLen; i++) {
            strout += strin.charAt(Math.random() * strin.length);
        }
        return strout;
    }

    getRandomNumber(randLen: number) {
        const strin = '1234567890';
        let strout = '';
        for (let i = 0; i < randLen; i++) {
            strout += strin.charAt(Math.random() * strin.length);
        }
        return strout;
    }

    getSerial(element: IMeterDevice) {
        return element.serial;
    }

    getSerialOK(element: IMeterDevice) {
        if (element.apartment && element.apartment.building) {
            switch (element.apartment.building.heatDeviceType.trim()) {
                case 'Honey_205':
                    return element.serial.trim().length !== 8;
            }
        }
        return false;
    }

    getManu(element: IMeterDevice) {
        switch (element.manufacturer) {
            case 17555:
                return 'QDS';
            case 25906:
                return 'LSE';
            default:
                return element.manufacturer;
        }
    }

    getMedium(element: IMeterDevice) {
        switch (element.medium) {
            case 8:
                return 'H.C.A.';
            case 7:
                return 'Water';
            default:
                return element.medium;
        }
    }

    getBulletStyle(element: IMeterDevice) {
        if (element.status === 0) {
            return { backgroundColor: 'green' };
        }
        if (element.status === 120) {
            return { backgroundColor: 'orange' };
        }
        return { backgroundColor: 'red' };
    }

    array_move(arr: Array<any>, oldIndex: number, newIndex: number) {
        if (newIndex >= arr.length) {
            let k = newIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
        console.log('Arr: ', arr);
        return arr; // for testing
    }

    getBattVoltage(element: IConcentrator) {
        return (element.vbatt / 1000).toFixed(3);
    }

    getMeters(id: number, src: string) {
        return this.http.get('/api/meters/' + src + '/' + id, this.getHeader());
    }

    getMeterStats() {
        return this.http.get('/api/meters/stats', this.getHeader());
    }

    deleteMeters(id: number, src: string) {
        return this.http.delete('/api/meters/' + src + '/' + id, this.getHeader());
    }

    getMeterDetail(id: number) {
        return this.http.get('/api/meters/' + id, this.getHeader());
    }
    saveMeter(item: IMeterDevice) {
        return this.http.put('/api/meters/' + item.meterDeviceId, item, this.getHeader());
    }
    getApartmets(id: number) {
        return this.http.get('/api/apartments/list/' + id, this.getHeader());
    }

    getApartmet(id: number) {
        return this.http.get('/api/apartments/' + id, this.getHeader());
    }

    getApartmetStats(id: number) {
        return this.http.get('/api/apartments/stats/' + id, this.getHeader());
    }

    addApartment(item: IApartment) {
        return this.http.post('/api/apartments', item, this.getHeader());
    }

    saveApartment(item: IApartment) {
        return this.http.put('/api/apartments/' + item.apartmentId, item, this.getHeader());
    }

    saveConcentrator(item: IConcentrator) {
        return this.http.put('/api/concentrators/' + item.concentratorId, item, this.getHeader());
    }

    findDevice(str: string) {
        return this.http.get('/api/meters/find/' + str, this.getHeader());
    }

    getConfirmationCode(newcode: string) {
        return this.http.get('/api/users/confcode/' + newcode, this.getHeader());
    }

    addConfirmationCode(newcode: string) {
        return this.http.get('/api/users/addcode/' + newcode, this.getHeader());
    }

    uploadFile(target: string, data: FormData) {
        return this.http.post('/api/inexp/' + target, data, this.getUserHeader());
    }

    cleanDB() {
        return this.http.get('/api/inexp/clean/4810', this.getHeader());
    }

    getDictionary() {
        this.http.get('/api/users/dictionaries', this.getHeader()).subscribe((data) => {
            this.dictionary = data as IDictionary[];
        });
    }

    editLabel(title: string, label: string, value: string) {
        return new Promise((resolve, reject) => {
            const dialogRef = this.dialog.open(EditlabelComponent, {
                data: { title, label, value }
            });

            dialogRef.afterClosed().subscribe((result) => {
                console.log(result);
                if (result == null) {
                    reject();
                } else {
                    resolve(result);
                }
            });
        });
    }
}
