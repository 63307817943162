<app-progressspinner *ngIf="progress"></app-progressspinner>

<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/buildings.svg" />
        <h2 class="tabtitle">Gestione Stabili</h2>

    </div>
    <div class="summary">
        <mat-form-field class="field field__find">
            <mat-label>Cerca</mat-label>
            <input matInput placeholder="Cerca" [(ngModel)]="toFind" (ngModelChange)="find()" />
        </mat-form-field>
    </div>
    <table mat-table  matSort [dataSource]="dataSource" class="mat-elevation-z8" (matSortChange)="SortChange($event)">
        <ng-container matColumnDef="name">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Nome</th>
            <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <ng-container matColumnDef="address">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Indirizzo</th>
            <td mat-cell *matCellDef="let element">
                {{ getAddress(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="code">
            <th mat-header-cell *matHeaderCellDef>Codice</th>
            <td mat-cell *matCellDef="let element">
                {{ element.code }}
            </td>
        </ng-container>

        <ng-container matColumnDef="devices">
            <th mat-header-cell *matHeaderCellDef>Dispositivi</th>
            <td mat-cell *matCellDef="let element">
                {{ element.heatDeviceType }}
            </td>
        </ng-container>

        <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef>Note</th>
            <td mat-cell *matCellDef="let element">
                {{ element.type }}
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button (click)="edit(element)" matTooltip="Modifica"><mat-icon>edit</mat-icon></button>
                <a href="/apartments/{{ element.buildingId }}" mat-icon-button matTooltip="Unità abitative"><mat-icon [matBadge]="element.apartmentsCount">apartment</mat-icon></a>

                <a mat-icon-button href="/devices/building/{{ element.buildingId }}" matTooltip="Contabilizzatori"><mat-icon [matBadge]="element.metersCount">dock</mat-icon></a>
                <button mat-icon-button (click)="showuser(element, false)" matTooltip="Utenti"><mat-icon>people_alt</mat-icon></button>
                <button mat-icon-button (click)="showuser(element, true)" matTooltip="Amministratori"><mat-icon color="warn">people_alt</mat-icon></button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="select(row)"></tr>

        <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
    <div class="actions mtop20">
        <button mat-raised-button (click)="addNew()">Aggiungi</button>
    </div>
</div>
