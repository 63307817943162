<div class="tabcontainer" *ngIf="editedUser !== undefined">
    <h2 class="tabtitle">Profilo {{ editedUser.email }}</h2>
    <hr />

    <cdk-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Dati anagrafici</mat-panel-title>
                <mat-panel-description></mat-panel-description>
                <!--<mat-icon>account_circle</mat-icon>-->
            </mat-expansion-panel-header>
            <div>
                <mat-form-field class="field-full-width">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome" [(ngModel)]="editedUser.firstName" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Cognome</mat-label>
                    <input matInput placeholder="Cognome" [(ngModel)]="editedUser.lastName" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Email</mat-label>

                    <input matInput type="email" [formControl]="emailFormControl" [errorStateMatcher]="matcher" placeholder="Email" [(ngModel)]="editedUser.email" />
                    <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')"> Please enter a valid email address </mat-error>
                    <mat-error *ngIf="emailFormControl.hasError('required')"> Email is <strong>required</strong> </mat-error>
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Telefono</mat-label>
                    <input matInput placeholder="Telefono" [(ngModel)]="editedUser.phone" />
                </mat-form-field>

                <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveProfile()">SALVA</button>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Cambia password</mat-panel-title>
                <mat-panel-description></mat-panel-description>
                <!--<mat-icon>account_circle</mat-icon>-->
            </mat-expansion-panel-header>
            <div>
                <mat-form-field class="field-full-width">
                    <mat-label>Password corrente</mat-label>
                    <input matInput placeholder="Password corrente" type="password" [(ngModel)]="editedUser.oldpassword" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Nuova password</mat-label>

                    <input matInput placeholder="Nuova password" type="password" [(ngModel)]="editedUser.newpassword" />
                </mat-form-field>
                <mat-form-field class="field-full-width">
                    <mat-label>Conferma password</mat-label>

                    <input matInput placeholder="Conferma nuova password" type="password" [(ngModel)]="confirmPassword" />
                </mat-form-field>
                <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveProfile()">SALVA</button>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>Aggiungi unità immobiliare</mat-panel-title>
                <mat-panel-description></mat-panel-description>
                <!--<mat-icon>account_circle</mat-icon>-->
            </mat-expansion-panel-header>
            <div>
                <mat-form-field>
                    <mat-label>Codice</mat-label>

                    <input matInput placeholder="Codice" [(ngModel)]="newcode" />
                </mat-form-field>
                <button mat-icon-button (click)="confirmCode()">
                    <mat-icon class="mat-18">add</mat-icon>
                </button>
                <div class="confirmeddata" *ngIf="response !== undefined">
                    <div *ngIf="response.response === 0">Stai aggiungendo</div>
                    <div *ngIf="response.response === 1" class="warn"><b>ATTENZIONE</b> sei già un utente di</div>
                    <div *ngIf="response.response === 2" class="warn"><b>ATTENZIONE</b> Nessuna unità immobiliare trovata, controlla il codice inserito</div>
                    <h3 *ngIf="response.apartment !== undefined">
                        Unità immobiliare <b>{{ response.apartment.name }}</b> situato in {{ getAddress(response.apartment.building) }}
                    </h3>
                    <h3 *ngIf="response.building !== undefined">
                        <b>Stabile {{ response.building.name }}</b> situato in {{ getAddress(response.building) }}
                    </h3>
                </div>
                <button mat-raised-button *ngIf="validateAddData()" (click)="confirmAddedData()">Conferma</button>
            </div>
        </mat-expansion-panel>
    </cdk-accordion>
</div>
