import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-menustat',
    templateUrl: './menustat.component.html',
    styleUrls: ['./menustat.component.scss']
})
export class MenustatComponent {
    @Input() link: string;
    @Input() caption: string;
    @Input() bkcolor: string;
    @Input() color: string;
    @Input() icon: string;
    @Input() badge: number;
    @Input() subcaption: string;
    constructor() {}


    getStyle() {
        return {
            color: this.color,
            backgroundColor: this.bkcolor
        };
    }
}
