import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-editlabel',
    templateUrl: './editlabel.component.html',
    styleUrls: ['./editlabel.component.scss']
})
export class EditlabelComponent implements OnInit {
    title: string;
    label: string;
    value: string;

    constructor(public dialogRef: MatDialogRef<EditlabelComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.title = data.title;
        this.label = data.label;
        this.value = data.value;
    }

    ngOnInit(): void {}

    close() {
        this.dialogRef.close(null);
    }
    save() {
        this.dialogRef.close(this.value);
    }
}
