import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { IBuilding, IUserProfile, IVerifyCode } from 'src/interfaces';
import { DataserviceService } from '../dataservice.service';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    editedUser: IUserProfile;
    confirmPassword: string;
    newcode = '';
    addedData = '';
    response: IVerifyCode;
    emailFormControl = new FormControl('', [Validators.required, Validators.email]);

    matcher = new MyErrorStateMatcher();
    constructor(public dataservice: DataserviceService) {}

    ngOnInit() {
        this.editedUser = {
            email: this.dataservice.currentUser.email,
            firstName: this.dataservice.currentUser.firstName,
            lastName: this.dataservice.currentUser.lastName,
            phone: this.dataservice.currentUser.phone,
            oldpassword: '',
            newpassword: ''
        } as IUserProfile;
    }

    saveProfile() {
        this.dataservice.updateProfile(this.editedUser);
    }

    validate() {
        console.log('test');
        if (this.editedUser.firstName.trim() === '' || this.editedUser.lastName.trim() === '' || this.editedUser.email.trim() === '') {
            return false;
        }
        if (this.editedUser.oldpassword !== '') {
            if (this.editedUser.newpassword.length < 8) {
                return false;
            }
            if (this.editedUser.newpassword !== this.confirmPassword) {
                return false;
            }
        }

        return true;
    }

    getAddress(item: IBuilding) {
        return item.address + ' ' + item.zip + ' ' + item.city + ' (' + item.province + ') ' + item.country;
    }

    confirmCode() {
        this.dataservice.getConfirmationCode(this.newcode).subscribe((data) => {
            this.response = data as IVerifyCode;
        });
    }
    confirmAddedData() {
        this.dataservice.addConfirmationCode(this.newcode).subscribe((data) => {
            this.dataservice.toastr.success('Struttura aggiunta al profilo');
            this.dataservice.whoAmI();
        });
    }

    validateAddData() {
        if (this.response === undefined) {
            return false;
        }
        return this.response.response === 0;
    }
}
