<div class="tabcontainer">
    <h2 class="tabtitle">Importa dati contabilizzatori</h2>

    <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload />

    <div class="file-upload">
        {{ fileName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>

<div class="tabcontainer">
    <h2 class="tabtitle">Importa dati appartamenti</h2>

    <input type="file" class="file-input" (change)="onAPFileSelected($event)" #fileApartmentUpload />

    <div class="file-upload">
        {{ fileApartmentName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileApartmentUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>

<div class="tabcontainer">
    <h2 class="tabtitle">Importa dati stabili</h2>

    <input type="file" class="file-input" (change)="onBuildingFileSelected($event)" #fileBuildingUpload />

    <div class="file-upload">
        {{ fileBuildingName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileBuildingUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>

<div class="tabcontainer">
    <h2 class="tabtitle">Importa tipi radiatori</h2>

    <input type="file" class="file-input" (change)="onRadFileSelected($event)" #fileRadUpload />

    <div class="file-upload">
        {{ fileRadName || 'No file uploaded yet.' }}

        <button mat-mini-fab color="primary" class="upload-btn" (click)="fileRadUpload.click()">
            <mat-icon>attach_file</mat-icon>
        </button>
    </div>
</div>


<div class="tabcontainer">
   <button mat-raised-button color="warn" (click)="removeAll()">Svuota DB</button>
</div>
