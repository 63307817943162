import { DataserviceService } from './../dataservice.service';
import { IBuilding } from './../../interfaces';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-building',
    templateUrl: './building.component.html',
    styleUrls: ['./building.component.scss']
})
export class BuildingComponent implements OnInit {
    currentItem: IBuilding;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<BuildingComponent>, @Inject(MAT_DIALOG_DATA) public data: IBuilding) {
        this.currentItem = { ...data };
    }

    ngOnInit(): void {}

    saveData() {
        this.dialogRef.close(this.currentItem);
    }

    cancel() {
        this.dialogRef.close();
    }

    validate() {
        return true;
    }

    reCode() {
        this.currentItem.code = this.dataservice.getRandomNumber(8);
    }
}
