<div class="tabcontainer">
    <div *ngIf="userConfirm === 0">
        <h2 class="tabtitle">Registra nuovo utente</h2>
        <hr />
        <mat-form-field class="field-full-width">
            <mat-label>Nome</mat-label>
            <input matInput placeholder="Nome" [(ngModel)]="user.firstName" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Cognome</mat-label>
            <input matInput placeholder="Cognome" [(ngModel)]="user.lastName" />
        </mat-form-field>

        <mat-form-field class="field-full-width">
            <mat-label>Email</mat-label>
            <input matInput placeholder="Email" [(ngModel)]="user.email" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Telefono</mat-label>
            <input matInput placeholder="Telefono" [(ngModel)]="user.phone" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Password</mat-label>
            <input matInput type="password" placeholder="Password" [(ngModel)]="user.password" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <mat-label>Conferma password</mat-label>
            <input matInput type="password" placeholder="Conferma password" [(ngModel)]="passwordConfirm" />
        </mat-form-field>

        <mat-form-field class="field-full-width">
            <mat-label>Codice identificativo</mat-label>
            <input matInput placeholder="Codice identificativo" [(ngModel)]="user.code" />
        </mat-form-field>

        <!--   <mat-form-field class="field-full-width">
            <input matInput placeholder="Seriale dispositivo" [(ngModel)]="user.device" />
        </mat-form-field>-->

        <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">Registra</button>
    </div>
    <div *ngIf="userConfirm === 1">
        <h2 class="tabtitle">Registrazione completata</h2>
        <p>
            La registrazione è stata completata con successo, riceverai una mail all'indirizzo <b>{{ user.email }}</b> con un link per confermare la tua identità entro 24 ore da ora
        </p>
    </div>
    <div *ngIf="userConfirm === 2">
        <h2 class="tabtitle">Si è verificato un errore</h2>
        <p>Si è verificato un errore del sistema, la registrazione non è andata a buon fine, si prega di riprovare. <br />Se il probelma persiste contattare il supporto utenti.</p>
    </div>
</div>
