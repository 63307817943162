<div class="tabcontainer">
    <h2 class="tabtitle">Unità abitativa</h2>
    <h2 class="tabtitle">
        {{ currentItem.building?.name }} <b>{{ currentItem.name }}</b>
    </h2>
    <hr />
    <div *ngIf="false">
        <mat-form-field class="field-full-width">
            <input matInput placeholder="Nome" [(ngModel)]="currentItem.name" />
        </mat-form-field>

        <mat-form-field class="field-full-width">
            <input matInput placeholder="Piano" [(ngModel)]="currentItem.floor" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <input matInput placeholder="Posizione" [(ngModel)]="currentItem.position" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <input matInput placeholder="Referente" [(ngModel)]="currentItem.owner" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <input matInput placeholder="Telefono" [(ngModel)]="currentItem.ownerphone" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <input matInput placeholder="Email" [(ngModel)]="currentItem.owneremail" />
        </mat-form-field>
        <mat-form-field class="field-full-width">
            <input matInput placeholder="Note" [(ngModel)]="currentItem.note" />
        </mat-form-field>
    </div>
    <div *ngIf="true"></div>
    <h3>Stima consumi ultimo mese</h3>
    <div class="costs">
        <div class="costs__costmonth">
            <div class="costs__costmonth__title">{{ getLastMonth() | date : 'MMMM yyy' }}</div>
            <div class="costs__costmonth__value">{{ getLastMonthValue() }}</div>
        </div>
        <!--   <div class="costs__costmonth">
            <div class="costs__costmonth__title">Consumo ad oggi</div>
            <div class="costs__costmonth__value">{{ getActualValue() }}€</div>
        </div>-->
    </div>
    <h3>Contabilizzatori</h3>
    <div class="heatcontainer">
        <div *ngFor="let item of currentItem.meterDevices" class="meter" [ngClass]="{ meter__selected: item.serial === selectetHeat }">
            <div class="meter__serial">
                {{ dataservice.getSerial(item) }} <b>{{ item.name }}</b>
            </div>
            <div class="meter__hr"></div>
            <div class="meter__prices">
                <div>
                    Consumo fino a {{ item.statisticalDate | date : 'MMMM yyy' }}: <b>{{ calcCost(item, item.statData) }}</b>
                </div>
                <div>
                    Consumo ultima lettura : <b>{{ calcCost(item,item.dayValue) }}</b>
                </div>
            </div>
            <span class="bulletstatus meter__status">
                <span class="bullet" [style]="dataservice.getBulletStyle(item)"></span>
            </span>
          <!--  <button class="buttedit" mat-icon-button (click)="edit(item)"><mat-icon>edit</mat-icon></button>-->
        </div>
    </div>
    <!--   <app-piechart *ngIf="currentItem.meterDevices.length > 0" (selectedItem)="selPie($event)" [data]="getData()" title="Ripartizione consumi"></app-piechart>-->
    <!--  <app-apapiechart [data]="getData()"></app-apapiechart>-->

    <h3>Ripartizione consumi</h3>
    <div class="piecont">
        <ngx-charts-pie-chart [scheme]="colorScheme" [results]="pieData" [gradient]="gradient" [legend]="showLegend" [legendPosition]="legendPosition" [labels]="showLabels" [doughnut]="false" (select)="onSelect($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)"> </ngx-charts-pie-chart>
    </div>
    <h3>Statistiche mesi pregressi</h3>
    <div class="barcont">
        <ngx-charts-bar-vertical [scheme]="colorScheme" [results]="graphData" [gradient]="gradient" [xAxis]="showXAxis" [yAxis]="showYAxis" [legend]="showLegend" [showXAxisLabel]="showXAxisLabelBar" [showYAxisLabel]="showYAxisLabel" [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel" (select)="onSelect($event)"> </ngx-charts-bar-vertical>
    </div>

    <!--   <button mat-raised-button *ngIf="dataservice.getPermission(true, 3)" (click)="addMeter()">Aggiungi/rimuovi contabilizzatori</button>-->

    <hr />
    <!--  <button mat-raised-button (click)="getStats()">Statistiche</button>-->
    <!-- <div *ngFor="let item of stats">{{ item.date | date }} {{ item.value }}€</div>-->
    <!--   <app-barchart *ngIf="graphData" [data]="graphData"></app-barchart>-->
</div>
