import { DataserviceService } from './../dataservice.service';
import { Component } from '@angular/core';

@Component({
    selector: 'app-utils',
    templateUrl: './utils.component.html',
    styleUrls: ['./utils.component.scss']
})
export class UtilsComponent {
    upkrunning = 0;
    progress = false;
    logs = '';

    constructor(public dataservice: DataserviceService) {}
    updateK(command: string, name: string) {
        this.upkrunning = 1;
        this.progress = true;
        this.dataservice.setUtil(command).subscribe(
            (data) => {
                this.logs += '<div class="logs--ok">\n comando <b>' + name + '</b> eseguito</div>';
                this.progress = false;
            },
            () => {
                this.logs += '<div class="logs--err">\n comando <b>' + name + '</b> fallito</div>';
                this.progress = false;
            }
        );
    }
}
