import { DataserviceService } from './../dataservice.service';
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IDictionary } from 'src/interfaces';

@Component({
    selector: 'app-dictcombo',
    templateUrl: './dictcombo.component.html',
    styleUrls: ['./dictcombo.component.scss']
})
export class DictcomboComponent implements OnInit {
    @Input() key: string;
    @Input() label: string;
    @Input() value: string;
    @Output() valueChange = new EventEmitter<string>();
    value2: string;
    items: IDictionary[] = [];

    constructor(public dataservice: DataserviceService) {}

    ngOnInit(): void {
        if (this.dataservice.dictionary === undefined) {
            this.dataservice.getDictionary();
        }
        this.items = this.dataservice.dictionary.filter((x) => x.group === this.key);
    }
}
