<ul class="nav navbar-nav mainnav">
    <img class="logo" src="assets/geco2.png" />
    <hr />
    <div class="welcome">
        Ciao <span>{{ dataservice.currentUser?.firstName }}</span>
    </div>
    <li [ngStyle]="dataservice.getAuth(true, 0, 0)" [routerLink]="['/']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">home</mat-icon> Home</li>
    <li [ngStyle]="dataservice.getAuth(true, 0, 0)" [routerLink]="['/profile']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">face</mat-icon>Profilo</li>
    <li [ngStyle]="dataservice.getAuth(true, 3, 0)" [routerLink]="['/projects']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">assignment</mat-icon>Progetti</li>
    <li [ngStyle]="dataservice.getAuth(true, 0, 0)" [routerLink]="['/buildings']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">location_city</mat-icon>Stabili</li>
    <!--<li [ngStyle]="dataservice.getAuth(true, 0, 0)" [routerLink]="['/apartments/my']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">house</mat-icon>Unità abitative</li>-->
    <li [ngStyle]="dataservice.getAuth(true, 3, 0)" [routerLink]="['/concentrators']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">memory</mat-icon>Concentratori</li>
    <li [ngStyle]="dataservice.getAuth(false, 0, 0)" [routerLink]="['/login']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">how_to_reg</mat-icon>Login</li>
    <li [ngStyle]="dataservice.getAuth(false, 0, 0)" [routerLink]="['/signup']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">how_to_reg</mat-icon>Registrati</li>
    <li [ngStyle]="dataservice.getAuth(true, 2, 0)" [routerLink]="['/users']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">account_box</mat-icon>Utenti</li>
    <li [ngStyle]="dataservice.getAuth(true, 2, 0)" [routerLink]="['/logs']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">view_list</mat-icon>Log eventi</li>
    <li [ngStyle]="dataservice.getAuth(true, 2, 0)" [routerLink]="['/help']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">help_outline</mat-icon>Assistenza</li>
    <li [ngStyle]="dataservice.getAuth(true, 3, 0)" [routerLink]="['/utils']" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }"><mat-icon aria-hidden="false">handyman</mat-icon>Utilità</li>
    <li [ngStyle]="dataservice.getAuth(true, 0, 0)" (click)="logout()"><mat-icon aria-hidden="false">exit_to_app</mat-icon>Logout</li>
</ul>
