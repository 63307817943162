<h2>{{ title }}</h2>
<mat-dialog-content>
    <mat-form-field class="field-full-width">
        <input matInput [placeholder]="label" [(ngModel)]="value" />
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="save()">OK</button>
    <button mat-button (click)="close()">Annulla</button>
</mat-dialog-actions>
