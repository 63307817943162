import { IBuilding, ISortParams } from './../../interfaces';
import { DataserviceService } from './../dataservice.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { BuildingComponent } from '../building/building.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserlistdialogComponent } from '../userlistdialog/userlistdialog.component';
import { MatSort } from '@angular/material/sort';

@Component({
    selector: 'app-managebuilding',
    templateUrl: './managebuilding.component.html',
    styleUrls: ['./managebuilding.component.scss']
})
export class ManagebuildingComponent implements OnInit {
    dataSource: MatTableDataSource<IBuilding>;

    allItems: IBuilding[] = [];
    sortedData: IBuilding[] = [];
    displayedColumns: string[] = ['name', 'address', 'code', 'devices', 'note', 'action'];
    progress = false;
    @ViewChild(MatSort) sort: MatSort;

    toFind = '';
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {}

    showuser(item: IBuilding, admin: boolean) {
        if (admin) {
            const dialogRef = this.dialog.open(UserlistdialogComponent, {
                data: { administrator: item }
            });
            dialogRef.afterClosed().subscribe((result) => {});
        } else {
            const dialogRef = this.dialog.open(UserlistdialogComponent, {
                data: { building: item }
            });
            dialogRef.afterClosed().subscribe((result) => {});
        }
    }
    /*
        this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
        this.dataSource.paginator = this.paginator;
        console.log('changed!!', this.toFind);
*/

    getFilteredDevices() {
        const sorted = this.sortedData.filter((x) => this.applyFilter(x));
        return sorted;
    }

    applyFilter(x: IBuilding): boolean {
        if (this.toFind.length > 3) {
            if (x.name.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
            if (this.getAddress(x).toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    SortChange(event) {
        console.log(event);
        const data = this.allItems.slice();
        const sortParams = event as ISortParams;
        sortParams.enabled = sortParams.direction !== '';
        if (sortParams.enabled) {
            this.sortedData = data.sort((a, b) => {
                switch (sortParams.active) {
                    case 'name':
                        return this.dataservice.compare(a.name, b.name, sortParams.direction);
                    case 'address':
                        return this.dataservice.compare(this.getAddress(a), this.getAddress(b), sortParams.direction);
                    default:
                        return 0;
                }
            });
        } else {
            this.sortedData = this.allItems.slice();
        }
        this.find();
    }

    find() {
        this.dataSource = new MatTableDataSource<IBuilding>(this.getFilteredDevices());
        this.dataSource.paginator = this.paginator;
    }

    async ngOnInit(): Promise<void> {
        this.progress = true;

        if (this.dataservice.currentUser === undefined) {
            await this.dataservice.whoAmIBlocking();
        }
        if (this.dataservice.currentUser.role > 2) {
            this.dataservice.getBuildings().subscribe((data) => {
                this.allItems = data as IBuilding[];
                this.sortedData = this.allItems.slice();
                this.dataSource = new MatTableDataSource<IBuilding>(this.getFilteredDevices());
                this.dataSource.paginator = this.paginator;
                this.progress = false;
            });
        } else {
            this.allItems = this.dataservice.currentUser.buildings;
            this.sortedData = this.allItems.slice();
            this.dataSource = new MatTableDataSource<IBuilding>(this.getFilteredDevices());
            this.dataSource.paginator = this.paginator;
        }
    }

    getAddress(item: IBuilding) {
        return (item.dug || '') + ' ' + item.address + ' ' + item.zip + ' ' + item.city + ' (' + item.province + ') ' + item.country;
    }

    select(item: IBuilding) {}

    edit(item: IBuilding) {
        const dialogRef = this.dialog.open(BuildingComponent, {
            data: item
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result);
                this.dataservice.saveBuildings(result as IBuilding).subscribe((x) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.ngOnInit().then(() => {});
                });
            }
        });
    }

    addNew() {
        const dialogRef = this.dialog.open(BuildingComponent, {
            data: {} as IBuilding
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result);
                this.dataservice.addBuildings(result as IBuilding).subscribe((x) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.ngOnInit().then(() => {});
                });
            }
        });
    }
}
