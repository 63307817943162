<h2>Concentratore</h2>
<mat-dialog-content>
    <mat-form-field class="field-full-width">
        <input matInput placeholder="Descrizione" [(ngModel)]="item.description" />
    </mat-form-field>
     <mat-form-field class="field">
        <input matInput type="number" placeholder="Durata lettura" [(ngModel)]="item.readTimer" />
    </mat-form-field>
    <mat-form-field class="field">
        <input matInput type="number" placeholder="Intervallo lettura" [(ngModel)]="item.sleepTimer" />
    </mat-form-field>
    <table>
        <tr>
            <td>MAC</td>
            <td>{{ item.mac }}</td>
        </tr>
        <tr>
            <td>IMEI</td>
            <td>{{ item.imei }}</td>
        </tr>
        <tr>
            <td>Operatore</td>
            <td>{{ item.carrier }}</td>
        </tr>
        <tr>
            <td>Vbatt</td>
            <td>{{ dataservice.getBattVoltage(item) }}V</td>
        </tr>
        <tr>
            <td>Ultimo aggiornamento</td>
            <td>{{ item.lastUpdate | date }}</td>
        </tr>
        <tr>
            <td>Struttura</td>
            <td>
                {{ item.building?.name }}
                <button mat-icon-button (click)="changeBuilding()">
                    <mat-icon class="mat-18">edit</mat-icon>
                </button>
            </td>
        </tr>
    </table>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">SALVA</button>
    <button mat-raised-button class="ideb" (click)="cancel()">Annulla</button>
</mat-dialog-actions>
