import { LoaderInterceptor } from './loader.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { EventlogsComponent } from './eventlogs/eventlogs.component';
import { NavmenuComponent } from './navmenu/navmenu.component';
import { MaterialModule } from './material-module';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';
import { UsermanagerComponent } from './usermanager/usermanager.component';
import { ConfirmdialogComponent } from './confirmdialog/confirmdialog.component';
import { LogsComponent } from './logs/logs.component';
import { ProfileComponent } from './profile/profile.component';
import { ProjectsComponent } from './projects/projects.component';
import { registerLocaleData } from '@angular/common';
import { MenutabComponent } from './menutab/menutab.component';
import { GraphbarComponent } from './graphbar/graphbar.component';
import localeIt from '@angular/common/locales/it';
import { BuildingComponent } from './building/building.component';
import { ApartmentComponent } from './apartment/apartment.component';
import { BuildinglistComponent } from './buildinglist/buildinglist.component';
import { ManageapartmentComponent } from './manageapartment/manageapartment.component';
import { ManagebuildingComponent } from './managebuilding/managebuilding.component';
import { ConcentratorsComponent } from './concentrators/concentrators.component';
import { HeatdetailComponent } from './heatdetail/heatdetail.component';
import { ConcentratordetailComponent } from './concentratordetail/concentratordetail.component';
import { DeviceslistComponent } from './deviceslist/deviceslist.component';
import { AdddevicesComponent } from './adddevices/adddevices.component';
import { MyapartmentComponent } from './myapartment/myapartment.component';
import { UseraddComponent } from './useradd/useradd.component';
import { UserverifyComponent } from './userverify/userverify.component';
import { PiechartComponent } from './piechart/piechart.component';
import { ImportdataComponent } from './importdata/importdata.component';
import { ApartdevicesComponent } from './apartdevices/apartdevices.component';
import { DictcomboComponent } from './dictcombo/dictcombo.component';
import { NumericconfirmComponent } from './numericconfirm/numericconfirm.component';
import { DevicefindComponent } from './devicefind/devicefind.component';
import { EditlabelComponent } from './editlabel/editlabel.component';
import { ConcentrarorlogsComponent } from './concentrarorlogs/concentrarorlogs.component';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { UserlistdialogComponent } from './userlistdialog/userlistdialog.component';
import { ProgressspinnerComponent } from './progressspinner/progressspinner.component';
import { PasswordrecoverComponent } from './passwordrecover/passwordrecover.component';
import { EditdictionaryComponent } from './editdictionary/editdictionary.component';
import { MenustatComponent } from './menustat/menustat.component';
import { UtilsComponent } from './utils/utils.component';
registerLocaleData(localeIt, 'it');
const appearance: MatFormFieldDefaultOptions = {
    appearance: 'outline'
};
@NgModule({
    declarations: [LogsComponent, AppComponent, HomeComponent, LoginComponent, EventlogsComponent, NavmenuComponent, ToolbarComponent, UsermanagerComponent, ProfileComponent, ConfirmdialogComponent, ProjectsComponent, MenutabComponent, GraphbarComponent, BuildingComponent, ApartmentComponent, BuildinglistComponent,  ManageapartmentComponent, ManagebuildingComponent, ConcentratorsComponent, HeatdetailComponent, ConcentratordetailComponent, DeviceslistComponent, AdddevicesComponent, MyapartmentComponent, UseraddComponent, UserverifyComponent, PiechartComponent, ImportdataComponent, ApartdevicesComponent, DictcomboComponent, NumericconfirmComponent, DevicefindComponent, EditlabelComponent, ConcentrarorlogsComponent, UserlistdialogComponent, ProgressspinnerComponent, PasswordrecoverComponent, EditdictionaryComponent, MenustatComponent, UtilsComponent],
    imports: [FormsModule, BrowserModule, AppRoutingModule, BrowserAnimationsModule, MaterialModule, HttpClientModule, ReactiveFormsModule, ToastrModule.forRoot(), NgxChartsModule],
    providers: [
        { provide: MatDialogRef, useValue: {} },
        { provide: MAT_DIALOG_DATA, useValue: [] },
        { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
        { provide: LOCALE_ID, useValue: 'it-IT' },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: appearance
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
