import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-importdata',
    templateUrl: './importdata.component.html',
    styleUrls: ['./importdata.component.scss']
})
export class ImportdataComponent implements OnInit {
    constructor(public dataservice: DataserviceService) {}
    fileName = '';
    fileRadName = '';
    fileApartmentName = '';
    fileBuildingName = '';
    ngOnInit(): void {}

    onRadFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileRadName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.dataservice.uploadFile('radparams', formData).subscribe(() => {
                this.dataservice.toastr.success('Caricamento riuscito');
            });
        }
    }

    onFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.dataservice.uploadFile('heats', formData).subscribe(() => {
                this.dataservice.toastr.success('Caricamento riuscito');
            });
        }
    }

    onAPFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileApartmentName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.dataservice.uploadFile('apartments', formData).subscribe(() => {
                this.dataservice.toastr.success('Caricamento riuscito');
            });
        }
    }
    onBuildingFileSelected(event) {
        const file: File = event.target.files[0];

        if (file) {
            this.fileBuildingName = file.name;
            const formData = new FormData();
            formData.append('file', file);
            this.dataservice.uploadFile('buildings', formData).subscribe(() => {
                this.dataservice.toastr.success('Caricamento riuscito');
            });
        }
    }

    removeAll() {
        this.dataservice.confirm('Sei sicuro di voler dare una spianata al DB?', ['Si', 'No']).then((resp) => {
            if (resp === 0) {
                this.dataservice.cleanDB().subscribe(
                    (data) => {
                        this.dataservice.toastr.success('Eliminazione eseguita');
                    },
                    () => {
                        this.dataservice.toastr.success('Errore');
                    }
                );
            }
        });
    }
}
