import { IDevicesStat, IMeterDeviceStats } from './../../interfaces';
import { Component, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    stats: IDevicesStat = undefined;
    constructor(public dataservice: DataserviceService) {}

    ngOnInit() {
        this.dataservice.getMeterStats().subscribe((data) => {
            this.stats = data as IDevicesStat;
        });
    }
}
