import { IConcentrator, IConcentratorLog } from './../../interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataserviceService } from '../dataservice.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-concentrarorlogs',
    templateUrl: './concentrarorlogs.component.html',
    styleUrls: ['./concentrarorlogs.component.scss']
})
export class ConcentrarorlogsComponent implements OnInit {
    logs: IConcentratorLog[] = [];
    dataSource: MatTableDataSource<IConcentratorLog>;
    displayedColumns = ['position', 'timestamp', 'interval', 'readed', 'total'];
    @ViewChild(MatPaginator) paginator: MatPaginator;
    currentItem: IConcentrator | undefined;
    constructor(private route: ActivatedRoute, public dialog: MatDialog, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            console.log('Pmap; ', map.get('mac'));
            const currentId = map.get('mac');

            this.dataservice.getConcentratorLogs(currentId).subscribe(
                (data) => {
                    this.logs = data as IConcentratorLog[];
                    this.dataSource = new MatTableDataSource<IConcentratorLog>(this.getFilteredDevices());
                    this.dataSource.paginator = this.paginator;
                },
                () => {
                    this.logs = [];
                }
            );
        });
    }
    getFilteredDevices() {
        return this.logs;
    }
    ngOnInit(): void {}

    archive() {}
}
