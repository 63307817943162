<div class="login-wrapper fx-layout-row fx-align--center-x fx-align--x-center">
    <mat-card class="box">
        <mat-card-header>
            <img src="assets/est.jpg" width="210px" />
            <!--<mat-card-title>Log in</mat-card-title>-->
        </mat-card-header>

        <div class="example-form">
            <mat-card-content>
                <mat-form-field class="example-full-width">
                  <mat-label>Email</mat-label>
                    <input matInput placeholder="Email" [(ngModel)]="user.email" />
                </mat-form-field>

                <mat-form-field class="example-full-width">
                  <mat-label>password</mat-label>

                    <input matInput type="password" placeholder="Password" [(ngModel)]="user.password" />
                </mat-form-field>
            </mat-card-content>
            <button mat-stroked-button color="accent" class="btn-block" (click)="login()">Log in</button>
            <div *ngIf="recover" class="loginerror">{{ this.message }}</div>
            <button *ngIf="recover" mat-stroked-button color="warn" class="btn-block" (click)="recoverPass()">Recupera password</button>
        </div>
    </mat-card>
</div>
