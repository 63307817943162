import { DataserviceService } from '../dataservice.service';
import { ISysUser } from 'src/interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
    selector: 'app-usermanager',
    templateUrl: './usermanager.component.html',
    styleUrls: ['./usermanager.component.scss']
})
export class UsermanagerComponent implements OnInit {
    users: MatTableDataSource<ISysUser>;
    selectedUser: ISysUser = { role: 0 } as ISysUser;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    spin = false;
    roles = [
        { value: 1, viewValue: 'User' },
        { value: 2, viewValue: 'Administrator' }
    ];

    displayedColumns: string[] = ['username', 'name', 'email', 'apa', 'build', 'password', 'role', 'action'];

    constructor(public dataservice: DataserviceService) {}

    ngOnInit() {
        this.spin = true;
        this.dataservice.getUsers(undefined, 0).subscribe((data) => {
            this.users = new MatTableDataSource<ISysUser>(data as ISysUser[]);
            this.spin = false;
        });
        /*  if (this.dataservice.currentUser.role === 3) {
            this.roles.push({ value: 3, viewValue: 'SYS Admin' });
        }*/
    }
    resetPass(user: ISysUser) {}
    select(user: ISysUser) {}

    adduser() {
        console.log(this.selectedUser);
        if (this.selectedUser.firstName && this.selectedUser.lastName && this.selectedUser.password && this.selectedUser.email) {
            this.dataservice.addUser(this.selectedUser).subscribe((data) => {
                this.dataservice.toastr.success('Utente aggiunto');
                this.selectedUser = {} as ISysUser;
                this.dataservice.getUsers(undefined, 0).subscribe((data) => {
                    this.users = new MatTableDataSource<ISysUser>(data as ISysUser[]);
                });
            });
        }
    }
    remove(user: ISysUser) {
        this.dataservice.confirm('Vuoi eliminare ' + user.firstName + ' ' + user.lastName + ' [' + user.email + ']?', ['Si', 'No']).then((res) => {
            if (res === 0) {
                this.dataservice.deleteUser(user).subscribe((data) => {
                    this.dataservice.toastr.success('Utente eliminato');
                    this.ngOnInit();
                });
            }
        });
    }
}
