<h1 mat-dialog-title>
    Stabile [<b>{{ currentItem.name }}</b
    >]
</h1>
<mat-dialog-content>
    <mat-form-field class="field-full-width">
        <mat-label>Nome</mat-label>
        <input matInput placeholder="Nome" [(ngModel)]="currentItem.name" />
    </mat-form-field>
    <mat-form-field class="field field__dug">
        <mat-label>Dug</mat-label>

        <input matInput placeholder="Dug" [(ngModel)]="currentItem.dug" />
    </mat-form-field>

    <mat-form-field class="field field__address">
        <mat-label>Indirizzo</mat-label>
        <input matInput placeholder="Indirizzo" [(ngModel)]="currentItem.address" />
    </mat-form-field>

    <mat-form-field class="field field__cap">
        <mat-label>CAP</mat-label>
        <input matInput placeholder="CAP" [(ngModel)]="currentItem.zip" />
    </mat-form-field>
    <mat-form-field class="field field__prov">
        <mat-label>Provincia</mat-label>

        <input matInput placeholder="Provincia" [(ngModel)]="currentItem.province" />
    </mat-form-field>
    <mat-form-field class="field field__city">
        <mat-label>Città</mat-label>

        <input matInput placeholder="Città" [(ngModel)]="currentItem.city" />
    </mat-form-field>
    <mat-form-field class="field field__country">
        <mat-label>Nazione</mat-label>

        <input matInput placeholder="Nazione" [(ngModel)]="currentItem.country" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <mat-label>Note</mat-label>

        <input matInput placeholder="Note" [(ngModel)]="currentItem.note" />
    </mat-form-field>

    <mat-form-field class="field field__percs">
        <mat-label>% volontaria</mat-label>

        <input matInput placeholder="% volontaria" [(ngModel)]="currentItem.voluntaryPerc" />
    </mat-form-field>

    <mat-form-field class="field field__percs">
        <mat-label>% involontaria</mat-label>

        <input matInput placeholder="% involontaria" [(ngModel)]="currentItem.involuntaryPerc" />
    </mat-form-field>

    <mat-form-field class="field field__type">
        <mat-label>Tipo dispositivo</mat-label>

        <input matInput placeholder="Tipo dispositivo" [(ngModel)]="currentItem.heatDeviceType" />
    </mat-form-field>
    <div>
        <mat-form-field>
            <mat-label>Codice</mat-label>

            <input matInput placeholder="Codice" [(ngModel)]="currentItem.code" />
        </mat-form-field>
        <button mat-icon-button (click)="reCode()">
            <mat-icon class="mat-18">restore_page</mat-icon>
        </button>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">SALVA</button>
    <button mat-raised-button class="ideb" (click)="cancel()">Annulla</button>
</mat-dialog-actions>
