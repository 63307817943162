import { Component } from '@angular/core';

@Component({
  selector: 'app-editdictionary',
  templateUrl: './editdictionary.component.html',
  styleUrls: ['./editdictionary.component.scss']
})
export class EditdictionaryComponent {

}
