import { ConcentratordetailComponent } from './../concentratordetail/concentratordetail.component';
import { DataserviceService } from './../dataservice.service';
import { IConcentrator } from './../../interfaces';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-concentrators',
    templateUrl: './concentrators.component.html',
    styleUrls: ['./concentrators.component.scss']
})
export class ConcentratorsComponent implements OnInit {
    items: IConcentrator[] = [];
    displayedColumns: string[] = ['position', 'description', 'mac', 'imei', 'imsi', 'building', 'batt', 'last', 'action'];

    constructor(public dialog: MatDialog, public dataservice: DataserviceService) {}

    ngOnInit(): void {
        this.dataservice.getConcentrators().subscribe((data) => {
            this.items = data as IConcentrator[];
        });
    }

    getMac(mac: string) {
        return mac;
    }

    getBattClass(element: IConcentrator) {
        return 'batt__ok';
    }

    getBattIcon(element: IConcentrator) {
        return 'battery_std';
    }

    select(item) {}

    edit(item: IConcentrator) {
        const dialogRef = this.dialog.open(ConcentratordetailComponent, {
            width: '800px',
            data: { ...item }
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result);
                const data = result as IConcentrator;

                this.dataservice.saveConcentrator(data).subscribe((x) => {
                    this.dataservice.toastr.success('Salvataggio eseguito');
                    this.ngOnInit();
                });
            }
        });
    }
}
