import { ISortParams } from './../../interfaces';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { IApartment, IMeterDevice, IBuilding } from 'src/interfaces';
import { AdddevicesComponent } from '../adddevices/adddevices.component';
import { DataserviceService } from '../dataservice.service';
import { HeatdetailComponent } from '../heatdetail/heatdetail.component';

@Component({
    selector: 'app-deviceslist',
    templateUrl: './deviceslist.component.html',
    styleUrls: ['./deviceslist.component.scss']
})
export class DeviceslistComponent implements OnInit {
    currentItem: IApartment | IBuilding;
    displayedColumnsStat: string[] = ['position', 'devid', 'cname', 'location', 'medium', 'devprod', 'status', 'energy', 'dayval', 'stat', 'readout', 'update', 'statdat', 'action'];
    displayedColumnsPara: string[] = ['position', 'devid', 'cname', 'location', 'medium', 'devprod', 'status', 'errdate', 'size', 'rtype', 'valk', 'ksource', 'action'];
    displayedColumnsRead: string[] = ['position', 'devid', 'cname', 'location', 'medium', 'devprod', 'status', 'errdate', 'concentrators', 'action'];
    Arr = Array;
    devices: IMeterDevice[] = [];
    sortedData: IMeterDevice[] = [];
    currentId: number;
    currentReq: string;
    alive = 0;
    old = 0;
    dead = 0;
    berror = 0;
    derr = 0;
    dorf = 0;

    fOffline = false;
    fError = false;
    fdError = false;
    fNotUpdated = false;
    fAlive = false;
    forf = false;
    dataSource: MatTableDataSource<IMeterDevice>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    toFind = '';
    dataSpecs = '0';
    progress = false;
    sortParams: ISortParams = { active: '', direction: '', enabled: false };

    constructor(private route: ActivatedRoute, public dialog: MatDialog, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            this.currentId = parseInt(map.get('id'), 10);
            this.currentReq = map.get('from');
            if (this.currentReq === 'apartment') {
                this.dataservice.getApartmet(this.currentId).subscribe(
                    (data) => {
                        this.currentItem = data as IApartment;
                    },
                    () => {
                        this.dataservice.goHome();
                    }
                );
            }
            if (this.currentReq === 'building') {
                this.dataservice.getBuilding(this.currentId).subscribe(
                    (data) => {
                        this.currentItem = data as IBuilding;
                    },
                    () => {
                        this.dataservice.goHome();
                    }
                );
            }
            this.progress = true;
            this.dataservice.getMeters(this.currentId, this.currentReq).subscribe((data) => {
                this.devices = data as IMeterDevice[];
                this.calcAlive();
                this.progress = false;
                this.sortedData = this.devices.slice();
                this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
                this.dataSource.paginator = this.paginator;
            });
        });
    }
    refresh() {
        if (this.currentReq === 'apartment') {
            this.dataservice.getApartmet(this.currentId).subscribe(
                (data) => {
                    this.currentItem = data as IApartment;
                },
                () => {
                    this.dataservice.goHome();
                }
            );
        }
        if (this.currentReq === 'building') {
            this.dataservice.getBuilding(this.currentId).subscribe(
                (data) => {
                    this.currentItem = data as IBuilding;
                },
                () => {
                    this.dataservice.goHome();
                }
            );
        }
        this.progress = true;
        this.dataservice.getMeters(this.currentId, this.currentReq).subscribe((data) => {
            this.devices = data as IMeterDevice[];
            this.calcAlive();
            this.progress = false;
            this.sortedData = this.devices.slice();
            this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
            this.dataSource.paginator = this.paginator;
        });
    }

    find() {
        this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
        this.dataSource.paginator = this.paginator;
        console.log('changed!!', this.toFind);
    }

    getDisplayedColumns() {
        switch (this.dataSpecs) {
            case '0':
                return this.displayedColumnsStat;
            case '1':
                return this.displayedColumnsPara;
            case '2':
                return this.displayedColumnsRead;
        }
    }

    getConcentrators(item: IMeterDevice) {
        let retval = '';
        for (const con of item.concentrators) {
            if (con.description) {
                retval += '<span>' + con.description + '</span>';
            } else {
                retval += '<span>' + con.description + '</span>';
            }
        }
    }

    sel(item: string) {
        switch (item) {
            case 'all':
                this.fError = false;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.fAlive = false;
                this.forf = false;
                break;
            case 'or':
                this.fError = false;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.fAlive = false;
                this.forf = !this.forf;
                break;
            case 'er':
                this.fError = !this.fError;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.forf = false;
                this.fAlive = false;
                break;
            case 'der':
                this.fdError = !this.fdError;
                this.fError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.forf = false;
                this.fAlive = false;
                break;
            case 'nu':
                this.fNotUpdated = !this.fNotUpdated;
                this.fdError = false;
                this.fError = false;
                this.fOffline = false;
                this.forf = false;
                this.fAlive = false;
                break;
            case 'ol':
                this.fOffline = !this.fOffline;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fError = false;
                this.forf = false;
                this.fAlive = false;
                break;
            case 'al':
                this.fAlive = !this.fAlive;
                this.fdError = false;
                this.fNotUpdated = false;
                this.fOffline = false;
                this.forf = false;
                this.fError = false;
                break;
        }
        this.find();
    }

    SortChange(event) {
        console.log(event);
        const data = this.devices.slice();
        this.sortParams = event as ISortParams;
        this.sortParams.enabled = this.sortParams.direction !== '';
        if (this.sortParams.enabled) {
            this.sortedData = data.sort((a, b) => {
                switch (this.sortParams.active) {
                    case 'devid':
                        return this.dataservice.compare(a.serial, b.serial, this.sortParams.direction);
                    case 'cname':
                        return this.dataservice.compare(a.name, b.name, this.sortParams.direction);
                    case 'location':
                        return this.dataservice.compare(this.getLocation(a), this.getLocation(b), this.sortParams.direction);
                    case 'medium':
                        return this.dataservice.compare(this.dataservice.getMedium(a), this.dataservice.getMedium(b), this.sortParams.direction);
                    case 'valk':
                        return this.dataservice.compare(this.getKVal(a), this.getKVal(b), this.sortParams.direction);
                    case 'rtype':
                        return this.dataservice.compare(this.getRadiatorType(a), this.getRadiatorType(b), this.sortParams.direction);
                    case 'ksource':
                        return this.dataservice.compare(a.details.forceCleanValue, b.details.forceCleanValue, this.sortParams.direction);
                    case 'status':
                        return this.dataservice.compare(a.status, b.status, this.sortParams.direction);
                    case 'readout':
                        return this.dataservice.compareDate(a.readoutTime, b.readoutTime, this.sortParams.direction);
                    case 'update':
                        return this.dataservice.compareDate(a.lastUpdateTime, b.lastUpdateTime, this.sortParams.direction);
                    case 'statdate':
                        return this.dataservice.compareDate(a.statisticalDate, b.statisticalDate, this.sortParams.direction);
                    default:
                        return 0;
                }
            });
        } else {
            this.sortedData = this.devices.slice();
        }
        this.find();
    }

    getItemId() {
        if (this.currentReq === 'apartment') {
            return (this.currentItem as IApartment).building.buildingId;
        }
        if (this.currentReq === 'building') {
            return (this.currentItem as IBuilding).apartments;
        }
    }

    getItemName() {
        if (this.currentReq === 'apartment') {
            return (this.currentItem as IApartment).building.name;
        }
        if (this.currentReq === 'building') {
            return (this.currentItem as IBuilding).name;
        }
    }
    getLocation(item: IMeterDevice) {
        let caption = '';
        if (item.apartment) {
            caption = item.apartment.name;
        }
        if (item.building) {
            caption += ' ' + item.building.name;
        }
        if (caption) {
            return caption.trim();
        }
        return '';
    }
    clear() {
        this.dataservice.deleteMeters(this.currentId, this.currentReq).subscribe((data) => {
            this.dataservice.toastr.success('Eliminazione effettuata');
            this.dataservice.getMeters(this.currentId, this.currentReq).subscribe((data) => {
                this.devices = data as IMeterDevice[];
                this.calcAlive();
                this.sortedData = this.devices.slice();
                this.dataSource = new MatTableDataSource<IMeterDevice>(this.getFilteredDevices());
                this.dataSource.paginator = this.paginator;
            });
        });
    }

    ngOnInit() {}

    select(item) {
        this.dataservice.getMeterDetail(item.meterDeviceId).subscribe((data) => {
            const dialogRef = this.dialog.open(HeatdetailComponent, {
                data: data as IMeterDevice
            });

            dialogRef.afterClosed().subscribe((result) => {
                /*  if (result) {
                    console.log(result);
                    this.dataservice.saveMeter(result as IMeterDevice).subscribe((x) => {
                        this.dataservice.toastr.success('Salvataggio eseguito');
                    });
                }*/
                this.refresh();
            });
        });
    }

    getKVal(element: IMeterDevice) {
        if (element.details.forceCleanValue === 3) {
            return '<span class="kval kval--custom">' + element.details.customKq.toFixed(2) + '</span>';
        }
        return element.details.k.toFixed(2);
    }

    getKsource(element: IMeterDevice) {
        //  console.log(element);
        if (element.details) {
            switch (element.details.forceCleanValue) {
                case 0:
                    if (element.apartment.building.metersClearValue) {
                        return 'Si';
                    } else {
                        return 'No';
                    }
                    break;
                case 1: {
                    return 'No (forzato)';
                }

                case 2: {
                    return 'Si (forzato)';
                }

                case 3: {
                    return 'val K pers.';
                }

                default:
                    break;
            }
        }
        return 'n/a';
    }

    getRadiatorSize(element: IMeterDevice) {
        if (element.details) {
            return element.details.width + '/' + element.details.height + '/' + element.details.depth;
        }
        return 'n/a';
    }

    getRadiatorType(element: IMeterDevice) {
        if (element.details && element.details.radiatorParameter) {
            return element.details.radiatorParameter.code;
        }
        return '';
    }

    getBulletStyle(element: IMeterDevice) {
        if (element.lastUpdateTime === undefined || element.lastUpdateTime == null) {
            return 'darkgray';
        }
        const now = new Date().getTime();
        const date = new Date(element.lastUpdateTime + 'Z');
        const diff = (now - date.getTime()) / 1000;
        if (diff > 1000000) {
            return { backgroundColor: 'black' };
        } else if (diff > 100000) {
            return { backgroundColor: 'darkgray' };
        }

        if (element.status === 0) {
            return { backgroundColor: 'green' };
        }
        if (element.status === 120) {
            return { backgroundColor: 'orange' };
        }
        return { backgroundColor: 'red' };
    }

    getIconStyle(element: IMeterDevice) {
        if (element.status === 0) {
            return { color: 'green' };
        }
        if (element.status === 120) {
            return { color: 'orange' };
        }
        return { color: 'red' };
    }

    getStatusIcon(element: IMeterDevice) {
        if (element.status === 0) {
            return '';
        }
        if (element.status === 120) {
            return 'plumbing';
        }
        return 'report_gmailerrorred';
    }

    dateValid(data: Date) {
        return new Date(data).getFullYear() !== 1;
    }

    getDeviceClass(device: IMeterDevice) {
        const date = new Date(device.lastUpdateTime + 'Z');
        const diff = (new Date().getTime() - date.getTime()) / 1000;
        if (diff > 43200) {
            // 12 h
            return 'time time--error';
        }
        if (diff > 3600) {
            return 'time time--warn';
        }
        if (diff > 600) {
            return 'time time--good';
        }
        return 'time time--perfect';
    }

    calcAlive() {
        this.alive = 0;
        this.old = 0;
        this.dead = 0;
        this.berror = 0;
        this.derr = 0;
        this.dorf = 0;
        const now = new Date().getTime();
        const nowDt = new Date();
        // nowDt.setMonth(nowDt.getMonth() - 1);
        for (const dev of this.devices) {
            dev.offline = false;
            dev.notUpdated = false;
            dev.error = dev.status !== 0;
            if (dev.apartment === undefined || dev.apartment == null) {
                this.dorf++;
            }
            if (this.dataservice.getSerialOK(dev)) {
                dev.dataError = true;
                this.derr++;
            } else {
                if (dev.status !== 0) {
                    this.berror++;
                }
                if (dev.lastUpdateTime === undefined || dev.lastUpdateTime == null) {
                    this.dead++;
                    dev.offline = true;
                } else {
                    const date = new Date(dev.lastUpdateTime + 'Z');
                    const diff = (now - date.getTime()) / 1000;

                    if (date.getMonth() !== nowDt.getMonth()) {
                        dev.offline = true;

                        this.dead++;
                    } else if (diff > 3600) {
                        this.old++;
                        this.alive++;

                        dev.notUpdated = true;
                    } else {
                        this.alive++;
                    }
                }
            }
        }
    }

    remFind() {
        this.toFind = '';
        this.find();
    }

    applyFilter(x: IMeterDevice): boolean {
        if (this.forf && x.apartment) {
            return false;
        }
        if (this.fAlive && x.offline) {
            return false;
        }
        if (this.fError && !x.error) {
            return false;
        }

        if (this.fdError && !x.dataError) {
            return false;
        }

        if (this.fNotUpdated && !x.notUpdated) {
            return false;
        }

        if (this.fOffline && !x.offline) {
            return false;
        }

        if (this.toFind.length > 3) {
            if (x.serial.indexOf(this.toFind) !== -1) {
                return true;
            }
            if (x.name && x.name.toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
            if (this.getLocation(x).toLocaleLowerCase().indexOf(this.toFind.toLocaleLowerCase()) !== -1) {
                return true;
            }
        } else {
            return true;
        }
        return false;
    }

    getFilteredDevices() {
        const sorted = this.sortedData.filter((x) => this.applyFilter(x));

        return sorted;
    }

    addMeter() {
        const dialogRef = this.dialog.open(AdddevicesComponent, {
            data: this.currentItem,
            width: '90vw',
            height: '90vh'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result);
                this.ngOnInit();
            }
        });
    }
}
