import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-numericconfirm',
    templateUrl: './numericconfirm.component.html',
    styleUrls: ['./numericconfirm.component.scss']
})
export class NumericconfirmComponent implements OnInit {
    @Input() value: number;
    oldvalue: number;

    constructor() {}
    getChecked() {
        return this.value === -1 ? 'radio_button_checked' : 'radio_button_unchecked';
    }

    isEnabled() {
      return this.value === -1;
    }
    ngOnInit(): void {
        this.oldvalue = this.value;
    }
    onChange() {
        if (this.value === -1) {
            this.value = this.oldvalue;
        } else {
            this.oldvalue = this.value;
            this.value = -1;
        }
    }
}
