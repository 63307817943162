import { DataserviceService } from './../dataservice.service';
import { Component, Inject, OnInit } from '@angular/core';
import { IApartment } from 'src/interfaces';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-apartment',
    templateUrl: './apartment.component.html',
    styleUrls: ['./apartment.component.scss']
})
export class ApartmentComponent implements OnInit {
    currentItem: IApartment;
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<ApartmentComponent>, @Inject(MAT_DIALOG_DATA) public data: IApartment) {
        this.currentItem = { ...data };
    }

    ngOnInit(): void {}

    saveData() {
        this.dialogRef.close(this.currentItem);
    }

    close() {
        this.dialogRef.close();
    }

    validate() {
        return true;
    }

    reCode() {
        this.currentItem.code = this.dataservice.getRandomNumber(10);
    }
}
