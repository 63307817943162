<h2>Ricerca Contabilizzatori</h2>
<mat-dialog-content>
    <mat-form-field class="field-full-width">
        <input matInput placeholder="Seriale dispositivo" (input)="onSearchChange($event.target.value)" />
    </mat-form-field>
    <div class="content">
        <div class="content__table">
            <table mat-table [dataSource]="items" class="mat-elevation-z8">
                <ng-container matColumnDef="position">
                    <th mat-header-cell *matHeaderCellDef>Num.</th>
                    <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
                </ng-container>
                <ng-container matColumnDef="devid">
                    <th mat-header-cell *matHeaderCellDef>Codice</th>
                    <td mat-cell *matCellDef="let element">{{ dataservice.getSerial(element) }}</td>
                </ng-container>

                <ng-container matColumnDef="devprod">
                    <th mat-header-cell *matHeaderCellDef>Prod.</th>
                    <td mat-cell *matCellDef="let element">
                        {{ dataservice.getManu(element) }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef>Impianto</th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.type }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button (click)="addDevice(element)">
                            <mat-icon *ngIf="!isSelected(element)" class="mat-18">add_circle</mat-icon>
                            <mat-icon *ngIf="isSelected(element)" class="mat-18">remove_circle</mat-icon>
                        </button>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{'content__table__selected': isSelected(row)}"></tr>

                <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Seleziona pagina"> </mat-paginator>
        </div>
        <div class="devAdded content__added">
            <div *ngFor="let item of addedItems" class="addedrounded">
                {{ dataservice.getSerial(item) }} {{ dataservice.getManu(item) }}
                <button mat-icon-button (click)="addDevice(item)">
                    <mat-icon class="mat-18">remove_circle</mat-icon>
                </button>
            </div>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="saveData()">SALVA</button>
    <button mat-raised-button (click)="cancel()">Annulla</button>
</mat-dialog-actions>
