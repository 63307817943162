<h1 mat-dialog-title>Recupera password</h1>
<mat-dialog-content>
    <mat-form-field class="field-full-width">
        <mat-label>Email</mat-label>
        <input matInput type="email" [formControl]="emailFormControl" [errorStateMatcher]="matcher" placeholder="Email" [(ngModel)]="email" />
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')"> Inserisci una email valida </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')"> Email è <strong>richiesto</strong> </mat-error>
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <mat-label>Telefono</mat-label>
        <input matInput type="text" [formControl]="phoneFormControl" [errorStateMatcher]="matcher" placeholder="Telefono" [(ngModel)]="phone" />
        <mat-error *ngIf="phoneFormControl.hasError('email') && !phoneFormControl.hasError('required')"> Inserisci il numero di telefono (compreso prefisso) </mat-error>
        <mat-error *ngIf="phoneFormControl.hasError('required')"> Telefono è <strong>richiesto</strong> </mat-error>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button [disabled]="validate()" (click)="saveData()">SALVA</button>
    <button mat-raised-button (click)="close()">ANNULLA</button>
</mat-dialog-actions>
