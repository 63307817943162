import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMeterDevice } from 'src/interfaces';
import { BuildingComponent } from '../building/building.component';
import { DataserviceService } from '../dataservice.service';

@Component({
    selector: 'app-heatdetail',
    templateUrl: './heatdetail.component.html',
    styleUrls: ['./heatdetail.component.scss']
})
export class HeatdetailComponent implements OnInit {
    currentItem: IMeterDevice;
    findSerials: IMeterDevice[];
    constructor(public dataservice: DataserviceService, public dialogRef: MatDialogRef<BuildingComponent>, @Inject(MAT_DIALOG_DATA) public data: IMeterDevice) {
        this.currentItem = { ...data };
        if (this.dataservice.dictionary === undefined) {
            this.dataservice.getDictionary();
        }
    }

    find() {
      this.dataservice.findFilterDevices(this.currentItem.serial).subscribe(data=>{
        this.findSerials = data as IMeterDevice[];
      });
    }

    ngOnInit(): void {}

    saveData() {
        this.dialogRef.close(this.currentItem);
    }
    saveDetail() {
        this.dataservice.saveMeter(this.currentItem).subscribe((x) => {
            this.dataservice.toastr.success('Salvataggio eseguito');
            this.dataservice.getMeterDetail(this.currentItem.meterDeviceId).subscribe((data) => {
                this.currentItem = data as IMeterDevice;
            });
        });
    }

    cancel() {
        this.dialogRef.close();
    }

    validate() {
        return true;
    }
}
