<div class="tabcontainer">
    <h2 class="tabtitle">Gestione Contabilizzatori calore</h2>

    <table mat-table [dataSource]="getFilteredDevices()" class="mat-elevation-z8">
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Num.</th>
            <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="devid">
            <th mat-header-cell *matHeaderCellDef>Codice</th>
            <td mat-cell *matCellDef="let element">{{ dataservice.getSerial(element) }}</td>
        </ng-container>

        <ng-container matColumnDef="devprod">
            <th mat-header-cell *matHeaderCellDef>Prod.</th>
            <td mat-cell *matCellDef="let element">
                {{ dataservice.getManu(element) }}
            </td>
        </ng-container>

        <ng-container matColumnDef="type">
            <th mat-header-cell *matHeaderCellDef>Impianto</th>
            <td mat-cell *matCellDef="let element">
                {{ element.type }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Stato</th>
            <td mat-cell *matCellDef="let element">
                <span class="bulletstatus">
                    <span class="bullet" [style]="dataservice.getBulletStyle(element)"></span>
                </span>
                <mat-icon aria-hidden="false" [style]="getIconStyle(element)">{{ getStatusIcon(element) }}</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="errdate">
            <th mat-header-cell *matHeaderCellDef>Data errore</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="dateValid(element.errorDate)">{{ element.errorDate | date: 'dd MMM yyy' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="energy">
            <th mat-header-cell *matHeaderCellDef>Cum. Energy</th>
            <td mat-cell *matCellDef="let element">
                {{ element.cumulativeEnergy }}
            </td>
        </ng-container>
        <ng-container matColumnDef="dayval">
            <th mat-header-cell *matHeaderCellDef>Day Value</th>
            <td mat-cell *matCellDef="let element">
                {{ element.dayValue }}
            </td>
        </ng-container>
        <ng-container matColumnDef="stat">
            <th mat-header-cell *matHeaderCellDef>Stat Value</th>
            <td mat-cell *matCellDef="let element">
                {{ element.statData }}
            </td>
        </ng-container>

        <ng-container matColumnDef="readout">
            <th mat-header-cell *matHeaderCellDef>Ultima lettura</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="dateValid(element.readoutTime)">{{ element.readoutTime | date: 'dd MMM yyy HH:mm' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="statdat">
            <th mat-header-cell *matHeaderCellDef>Dato statistico</th>
            <td mat-cell *matCellDef="let element">
                <div *ngIf="dateValid(element.statisticalDate)">{{ element.statisticalDate | date: 'MMM yyy' }}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"></td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="select(row)"></tr>

        <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
    </table>
</div>
