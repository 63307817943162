<mat-autocomplete #autocomplete="matAutocomplete">
    <mat-option *ngFor="let item of findSerials" [value]="item.serial" class="findtab">
        <mat-icon class="findicon--ko" *ngIf="item.apartment">report_problem</mat-icon>
        <mat-icon class="findicon--ok" *ngIf="item.apartment == undefined">check_circle</mat-icon>

        <span>{{ item.serial }} - {{ item.name }} - {{ item.apartment.name }} - {{ item.name }} </span></mat-option
    >
</mat-autocomplete>
<h2 mat-dialog-title>Device {{ dataservice.getSerial(currentItem) }} - {{ dataservice.getManu(currentItem) }} - {{ dataservice.getMedium(currentItem) }}</h2>
<mat-dialog-content class="content">
    <mat-card class="content__card">
        <mat-card-header>
            <mat-card-subtitle>Dati ricevuti dai concentratori</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="hsep--h5"></div>
            <div *ngIf="currentItem.concentrators">
                <div class="concentrators" *ngFor="let item of currentItem.concentrators">{{ item.mac }} {{ item.description }}</div>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="content__card">
        <mat-card-header>
            <mat-card-subtitle>Calcolo valori</mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <div class="card-container">
                <div class="hsep--h5"></div>

                <mat-form-field>
                    <mat-label>Dati in chiaro</mat-label>
                    <mat-select [(ngModel)]="currentItem.details.forceCleanValue">
                        <mat-option *ngIf="currentItem.apartment.building.metersClearValue" [value]="0">Si (da stabile)</mat-option>
                        <mat-option *ngIf="!currentItem.apartment.building.metersClearValue" [value]="0">No (da stabile)</mat-option>
                        <mat-option [value]="1">No (Forzato)</mat-option>
                        <mat-option [value]="2">Si (Forzato)</mat-option>
                        <mat-option [value]="3">Usa K pers</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="full-size-form">
                    <mat-label>Potenza in W</mat-label>
                    <input matInput placeholder="Potenza" type="number" [(ngModel)]="currentItem.details.customKq" [disabled]="currentItem.details.forceCleanValue != 3" />
                </mat-form-field>

                <div class="details__kvalues__k">
                    Lettura: <b>{{ currentItem.readoutStatData }}</b>
                </div>
                <div class="details__kvalues__k">
                    Valore: <b>{{ currentItem.statData }}</b>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
    <mat-card class="content__card">
        <mat-card-header>
            <mat-card-subtitle>Parametri</mat-card-subtitle>
        </mat-card-header>

        <mat-card-content>
            <div class="hsep--h5"></div>
            <div class="heatsize">
                <mat-form-field class="field-full-width">
                    <mat-label>Nome</mat-label>
                    <input matInput placeholder="Nome" [(ngModel)]="currentItem.name" />
                </mat-form-field>
            </div>
            <div class="heatsize">
                <mat-form-field class="field-full-width">
                    <mat-label>Codice</mat-label>
                    <input matInput placeholder="Code" [(ngModel)]="currentItem.serial" [matAutocomplete]="autocomplete" (ngModelChange)="find()" />
                </mat-form-field>
            </div>
            <div class="heatsize">
                <mat-form-field class="heatsize__number">
                    <mat-label>L (mm)</mat-label>
                    <input matInput placeholder="Larghezza" [(ngModel)]="currentItem.details.width" />
                </mat-form-field>
                <mat-form-field class="heatsize__number">
                    <mat-label>A (mm)</mat-label>
                    <input matInput placeholder="Altezza" [(ngModel)]="currentItem.details.height" />
                </mat-form-field>
                <mat-form-field class="heatsize__number">
                    <mat-label>P (mm)</mat-label>
                    <input matInput placeholder="Profondità" [(ngModel)]="currentItem.details.depth"
                /></mat-form-field>
            </div>
            <div class="heatsize">
                <mat-form-field class="heatsize__number">
                    <mat-label>Elementi</mat-label>
                    <input matInput placeholder="Elementi" [(ngModel)]="currentItem.details.elements" />
                </mat-form-field>
                <mat-form-field class="heatsize__number">
                    <mat-label>Colonne</mat-label>
                    <input matInput placeholder="Colonne" [(ngModel)]="currentItem.details.columns" />
                </mat-form-field>
            </div>
            <div *ngIf="currentItem.details" class="details">
                <div class="radtype">
                    <div>{{ currentItem.details.radiatorCode }}</div>
                    <img src="/assets/radiator/{{ currentItem.details.radiatorParameter?.image }}" />
                </div>
                <div class="details__kvalues">
                    <div class="details__kvalues__k">
                        K: <b>{{ currentItem.details.k.toFixed(2) }}</b>
                    </div>
                    <div class="details__kvalues__k">
                        Kq: <b>{{ currentItem.details.kq.toFixed(2) }}W</b>
                    </div>
                    <div class="details__kvalues__k">
                        Kc: <b>{{ currentItem.details.radiatorParameter?.k75_2f_205.toFixed(2) }}W</b>
                    </div>
                    <div class="details__kvalues__k">
                        Cj: <b>{{ currentItem.details.radiatorParameter?.cj }}</b>
                    </div>
                </div>

                <!--<app-dictcombo label="Codice Radiatore" [(value)]="currentItem.details.radiatorCode" key="kctype"></app-dictcombo>-->
                <!--    <app-dictcombo label="Tipo KC" [(value)]="currentItem.details.kcType" key="kctype"></app-dictcombo>
        <app-dictcombo label="Tipo KC" [(value)]="currentItem.details.kcType" key="kctype"></app-dictcombo>-->
            </div>
        </mat-card-content>
    </mat-card>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-raised-button (click)="cancel()">Chiudi</button>
    <button mat-raised-button (click)="saveDetail()" color="warn">Aggiorna</button>
</mat-dialog-actions>
