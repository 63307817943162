<div class="tabcontainer">
    <div class="sectiontitleicon">
        <img src="/assets/meter.png" />
        <h2 class="tabtitle">Log letture Concentratore {{currentItem?.description}}</h2>
        <div *ngIf="currentItem" class="breadcrumbs">
             <a href="/buildings/">Stabili</a>&nbsp;&gt;&gt;&nbsp; <a href="/apartments/{{ currentItem?.building.buildingId}}">{{ currentItem?.building.name }}</a
            >&nbsp;&gt;&gt;&nbsp; <span>{{ currentItem.description }} [{{ currentItem.mac }}]</span>
        </div>
     <!--   <mat-form-field class="field field__find">
            <input matInput placeholder="Trova" [(ngModel)]="toFind" />
        </mat-form-field>
        <button mat-icon-button (click)="find()">
            <mat-icon class="mat-18">youtube_searched_for</mat-icon>
        </button>
    </div>-->
    <button mat-raised-button (click)="archive()">Archivia</button>


    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef>Num.</th>
            <td mat-cell *matCellDef="let element; let i = index">{{ i + 1 }}</td>
        </ng-container>
        <ng-container matColumnDef="timestamp">
            <th mat-header-cell *matHeaderCellDef>Data</th>
            <td mat-cell *matCellDef="let element">{{ element.timestamp | date: 'dd MMM yyyy - HH:mm:ss'}}</td>
        </ng-container>

        <ng-container matColumnDef="interval">
            <th mat-header-cell *matHeaderCellDef>Intervallo</th>
            <td mat-cell *matCellDef="let element">{{ element.readTimer }} min.</td>
        </ng-container>

        <ng-container matColumnDef="readed">
            <th mat-header-cell *matHeaderCellDef>Dispo. Letti</th>
            <td mat-cell *matCellDef="let element">
               {{ element.readLen }}
            </td>
        </ng-container>

        <ng-container matColumnDef="total">
            <th mat-header-cell *matHeaderCellDef>Disp. Totali</th>
            <td mat-cell *matCellDef="let element">
                {{ element.totalLen }}
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

        <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
    </table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons aria-label="pagina"> </mat-paginator>
</div>
