import { IMeterDeviceStats } from './../../interfaces';
import { AdddevicesComponent } from './../adddevices/adddevices.component';
import { DataserviceService } from './../dataservice.service';
import { Component, OnInit } from '@angular/core';
import { IApartment, IGraphData, IMeterDevice } from 'src/interfaces';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-myapartment',
    templateUrl: './myapartment.component.html',
    styleUrls: ['./myapartment.component.scss']
})
export class MyapartmentComponent implements OnInit {
    currentItem = { meterDevices: [] } as IApartment;
    currentId: number;
    selectetHeat: any;
    stats: IMeterDeviceStats[];
    graphData: IGraphData[] = [];
    pieData: IGraphData[] = [];

    view: any[] = [700, 300];
    colorScheme = {
        // domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
        domain: ['#003f5c', '#2f4b7c', '#665191', '#a05195', '#d45087', '#f95d6a', '#ff7c43', '#ffa600']
    };
    // options
    legend = false;
    showLabels = true;
    animations = true;
    xAxis = true;
    yAxis = true;
    showYAxisLabel = true;
    showXAxisLabel = true;

    showXAxis = true;
    showYAxis = true;
    xAxisLabel = 'Contabilizzatore';
    yAxisLabel = 'Consumo';
    showXAxisLabelBar = 'Mese';
    timeline = false;

    gradient = true;
    showLegend = false;
    isDoughnut = false;
    legendPosition = 'below';

    constructor(public dialog: MatDialog, private route: ActivatedRoute, public dataservice: DataserviceService) {
        this.route.paramMap.subscribe(async (map) => {
            console.log('Pmap; ', map.get('id'));
            this.currentId = parseInt(map.get('id'), 10);
            this.ngOnInit();
        });
    }
    ngOnInit(): void {
        this.dataservice.getApartmet(this.currentId).subscribe(
            (data) => {
                this.currentItem = data as IApartment;
                this.getStats();
                this.pieData = this.getData();
            },
            () => {
                this.dataservice.goHome();
            }
        );
    }

    edit(device: IMeterDevice) {
        this.dataservice.editLabel('Aggiorna nome', 'nome', device.name).then((data) => {
            console.log('Updated:', data);
            device.name = data as string;
            this.dataservice.saveMeterName(device).subscribe(() => {
                this.dataservice.toastr.success('Aggiornamento eseguito');
                this.ngOnInit();
            });
        });
    }

    validate() {
        return true;
    }
    getLastMonth() {
        if (this.currentItem.meterDevices != null) {
            if (this.currentItem.meterDevices.length > 0) {
                return this.currentItem.meterDevices[0].statisticalDate;
            }
        }
    }
    getLastMonthValue() {
        let val = 0;
        for (const meter of this.currentItem.meterDevices) {
            val += this.calcCost(meter, meter.statData);
        }
        return val;
    }

    getActualValue() {
        let val = 0;
        for (const meter of this.currentItem.meterDevices) {
            val += meter.dayValue;
        }
        return val;
    }

    addMeter() {
        const dialogRef = this.dialog.open(AdddevicesComponent, {
            data: this.currentItem,
            width: '90vw',
            height: '90vh'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result);
                this.ngOnInit();
            }
        });
    }

    /*
    getData() {
        const response: IGraphData[] = [];
        let i = 0;
        for (const meter of this.currentItem.meterDevices) {
            response.push({ key: meter, value: meter.statData, index: i });
            i++;
        }
        return response;
    }
*/

    getData() {
        const response: IGraphData[] = [];
        let i = 0;
        for (const meter of this.currentItem.meterDevices) {
            let name = meter.name;
            if (name === '' || name === undefined) {
                name = meter.serial;
            }
            response.push({ name, value: this.calcCost(meter, meter.statData), label: 'pippo' });
            i++;
        }
        return response;
    }

    getStats() {
        this.dataservice.getApartmetStats(this.currentId).subscribe((data) => {
            this.stats = data as IMeterDeviceStats[];
            this.graphData = [];
            for (const st of this.stats) {
                st.date = new Date(st.date);
                const month = this.dataservice.monthNamesIt[st.date.getMonth()] + ' ' + (st.date.getFullYear() - 2000).toString();
                this.graphData.push({ name: month, value: st.value, label: 'pippo' });
            }
        });
    }

    selPie(event) {
        this.selectetHeat = event;
    }

    onSelect(data): void {
        if (data) {
            for (const met of this.currentItem.meterDevices) {
                //    console.log('Test ', met.name, data.value.name);

                if (met.name === data.value.name || met.serial === data.value.name) {
                    this.selectetHeat = met.serial;
                    break;
                }
            }
        }
    }

    calcCost(item: IMeterDevice, value: number) {
        return Math.round(value);
    }

    onActivate(data): void {
        if (data) {
            for (const met of this.currentItem.meterDevices) {
                //  console.log('Test ', met.name, data.value.name);

                if (met.name === data.value.name || met.serial === data.value.name) {
                    this.selectetHeat = met.serial;
                    break;
                }
            }
        }
    }

    onDeactivate(data): void {
        this.selectetHeat = undefined;
    }
}
