import { DataserviceService } from './../dataservice.service';
import { IGraphData } from './../../interfaces';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import * as d3 from 'd3-selection';
import * as d3Scale from 'd3-scale';
import * as d3Array from 'd3-array';
import * as d3Axis from 'd3-axis';
import * as d3Shape from 'd3-shape';

@Component({
    selector: 'app-piechart',
    templateUrl: './piechart.component.html',
    styleUrls: ['./piechart.component.scss']
})
export class PiechartComponent implements OnInit {
    @Input() title: string;
    @Input() data: IGraphData[];
    @Output() selectedItem = new EventEmitter<string>();
    label: string = undefined;
    value = 0;
    margin = { top: 20, right: 20, bottom: 30, left: 50 };
    width: number;
    height: number;
    radius: number;

    arc: any;
    labelArc: any;
    labelPer: any;
    pie: any;
    color: any;
    svg: any;

    constructor(public dataservice: DataserviceService) {
        this.width = 900 - this.margin.left - this.margin.right;
        this.height = 500 - this.margin.top - this.margin.bottom;
        this.radius = Math.min(this.width, this.height) / 2;
    }

    ngOnInit() {
        this.initSvg();
        this.drawPie();
    }

    initSvg() {
        this.color = d3Scale.scaleOrdinal().range(['#FFA500', '#00FF00', '#FF0000', '#6b486b', '#FF00FF', '#d0743c', '#00FA9A']);
        this.arc = d3Shape
            .arc()
            .outerRadius(this.radius - 10)
            .innerRadius(0);
        this.labelArc = d3Shape
            .arc()
            .outerRadius(this.radius - 40)
            .innerRadius(this.radius - 40);

        this.labelPer = d3Shape
            .arc()
            .outerRadius(this.radius - 80)
            .innerRadius(this.radius - 80);

        this.pie = d3Shape
            .pie()
            .sort(null)
            .value((d: any) => d.value);

        this.svg = d3
            .select('#pieChart')
            .append('svg')
            .attr('width', '100%')
            .attr('height', '100%')
            .attr('viewBox', '0 0 ' + Math.min(this.width, this.height) + ' ' + Math.min(this.width, this.height))
            .append('g')
            .attr('transform', 'translate(' + Math.min(this.width, this.height) / 2 + ',' + Math.min(this.width, this.height) / 2 + ')');
    }

    drawPie() {
        const tooltip = d3.select('body').append('div').attr('class', 'toolTip');
        const inthis = this;
        const g = this.svg.selectAll('.arc').data(this.pie(this.data)).enter().append('g').attr('class', 'arc');
        g.append('path')
            .attr('d', this.arc)
            .style('fill', (d: any) => this.color(d.data.index))
            .style('cursor', 'pointer')
            .on('mouseover', function (d) {
                console.log(d);
                inthis.selectedItem.emit(d.data.key.serial);

                d3.select(this).style('opacity', 0.6);
            })
            .on('mouseout', function () {
                inthis.selectedItem.emit(undefined);

                d3.select(this).style('opacity', 1);
            });
        g.append('text')
            .attr('transform', (d: any) => 'translate(' + this.labelArc.centroid(d) + ')')
            .attr('dy', '.35em')
            .attr('z-index', 10000)
            .text((d: any) => d.data.key.name);

        g.append('text')
            .attr('transform', (d: any) => 'translate(' + this.labelPer.centroid(d) + ')')
            .attr('dy', '.35em')
            .attr('z-index', 10000)
            .text((d: any) => d.data.value);
        /*  g.transition()
            .duration(200)
            .delay((d, i) => {
                return i * 50;
            });*/
    }
}
