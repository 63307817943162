import { ManageapartmentComponent } from './manageapartment/manageapartment.component';
import { UtilsComponent } from './utils/utils.component';
import { ConcentrarorlogsComponent } from './concentrarorlogs/concentrarorlogs.component';
import { ImportdataComponent } from './importdata/importdata.component';
import { UserverifyComponent } from './userverify/userverify.component';
import { UseraddComponent } from './useradd/useradd.component';
import { MyapartmentComponent } from './myapartment/myapartment.component';
import { DeviceslistComponent } from './deviceslist/deviceslist.component';
import { ConcentratorsComponent } from './concentrators/concentrators.component';
import { ManagebuildingComponent } from './managebuilding/managebuilding.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UsermanagerComponent } from './usermanager/usermanager.component';
import { ProfileComponent } from './profile/profile.component';
import { ProjectsComponent } from './projects/projects.component';
import { LogsComponent } from './logs/logs.component';

const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'signup', component: UseraddComponent },
    { path: 'users', component: UsermanagerComponent },
    { path: 'verify/:id', component: UserverifyComponent },
    { path: 'projects', component: ProjectsComponent },
    { path: 'profile', component: ProfileComponent },
    { path: 'logs', component: LogsComponent },
    { path: 'buildings', component: ManagebuildingComponent },
    { path: 'apartments/:id', component: ManageapartmentComponent },
    { path: 'apartment/:id', component: MyapartmentComponent },
    { path: 'devices/:from/:id', component: DeviceslistComponent },
    { path: 'import', component: ImportdataComponent },
    { path: 'concentrators', component: ConcentratorsComponent },
    { path: 'concentrator/logs/:mac', component: ConcentrarorlogsComponent },
    { path: 'utils', component: UtilsComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule {}
