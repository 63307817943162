import { BuildinglistComponent } from './../buildinglist/buildinglist.component';
import { IBuilding, IConcentrator } from './../../interfaces';
import { Component, Inject, OnInit } from '@angular/core';
import { DataserviceService } from '../dataservice.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-concentratordetail',
    templateUrl: './concentratordetail.component.html',
    styleUrls: ['./concentratordetail.component.scss']
})
export class ConcentratordetailComponent implements OnInit {
    constructor(public dialog: MatDialog, public dataservice: DataserviceService, public dialogRef: MatDialogRef<ConcentratordetailComponent>, @Inject(MAT_DIALOG_DATA) public data: IConcentrator) {
        this.item = { ...data };
    }
    item: IConcentrator;
    ngOnInit(): void {}
    changeBuilding() {
        const dialogRef = this.dialog.open(BuildinglistComponent, {});

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                console.log(result);
                const data = result as IBuilding;

                this.item.building = data;
            }
        });
    }
    validate() {
        return this.item.description !== undefined && this.item.description !== null && this.item.description.trim() !== '';
    }
    cancel() {
        this.dialogRef.close();
    }
    saveData() {
        this.dialogRef.close(this.item);
    }
}
