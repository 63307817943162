<app-progressspinner *ngIf="progress"></app-progressspinner>
<div class="tabcontainer">
    <div *ngIf="key !== 'my'" class="sectiontitleicon">
        <img src="/assets/home.png" />
        <h2 class="tabtitle">
            Unità abitative stabile <b>{{ currentBuilding?.name }}</b>
        </h2>
    </div>
    <div *ngIf="currentBuilding" class="buildingaddress">{{ currentBuilding.dug }} {{ currentBuilding.address }} {{ currentBuilding.number }} {{ currentBuilding.zip }} {{ currentBuilding.city }} ({{ currentBuilding.province }})</div>
    <div class="summary">
        <mat-form-field class="field field__find">
            <mat-label>Cerca</mat-label>
            <input matInput placeholder="Cerca" [(ngModel)]="toFind" (ngModelChange)="find()" />
        </mat-form-field>
    </div>
    <h2 class="tabtitle" *ngIf="key === 'my'">Le mie unità abitative</h2>
    <div class="apatiles" *ngIf="key === 'my'">
        <app-menutab *ngFor="let item of allItems; let i = index" icon="home" [caption]="item.name" color="white" [bkcolor]="dataservice.colors[i % 10]" [link]="'/apartment/' + item.apartmentId"></app-menutab>
    </div>
    <div *ngIf="key !== 'my'">
        <table mat-table matSort [dataSource]="dataSource" class="mat-elevation-z8" (matSortChange)="SortChange($event)">
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Nome</th>
                <td mat-cell *matCellDef="let element">{{ element.name }}</td>
            </ng-container>
            <ng-container matColumnDef="stair">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Scala</th>
                <td mat-cell *matCellDef="let element">{{ element.staircase }}</td>
            </ng-container>
            <ng-container matColumnDef="floor">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Piano</th>
                <td mat-cell *matCellDef="let element">{{ element.floor }}</td>
            </ng-container>
            <ng-container matColumnDef="phone">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Telefono</th>
                <td mat-cell *matCellDef="let element">{{ element.ownerphone }} {{ element.ownerphone2 }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let element"></td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef>Codice</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.code }}
                </td>
            </ng-container>

            <ng-container matColumnDef="note">
                <th mat-header-cell *matHeaderCellDef>Note</th>
                <td mat-cell *matCellDef="let element">
                    {{ element.note }}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button (click)="select(element)"><mat-icon>edit</mat-icon></button>
                    <a mat-icon-button href="/apartment/{{ element.apartmentId }}"><mat-icon>visibility</mat-icon></a>
                    <a mat-icon-button href="/devices/apartment/{{ element.apartmentId }}"><mat-icon [matBadge]="element.metersCount">dock</mat-icon></a>
                    <button mat-icon-button (click)="showuser(element)"><mat-icon>people_alt</mat-icon></button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

            <!--<tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>-->
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
    </div>
    <div class="actions mtop20">
        <button mat-raised-button (click)="addNew()">Aggiungi unità abitativa nello stabile</button>
    </div>
</div>
