<h2>Unità abitativa</h2>
<mat-dialog-content>
    <mat-form-field class="field-full-width">
        <mat-label>Nome</mat-label>

        <input matInput placeholder="Nome" [(ngModel)]="currentItem.name" />
    </mat-form-field>

    <mat-form-field class="field field__floor">
        <mat-label>Piano</mat-label>

        <input matInput placeholder="Piano" [(ngModel)]="currentItem.floor" />
    </mat-form-field>
    <mat-form-field class="field field__position">
        <mat-label>Scala</mat-label>

        <input matInput placeholder="Scala" [(ngModel)]="currentItem.staircase" />
    </mat-form-field>
    <mat-form-field class="field field__position">
        <mat-label>Posizione</mat-label>

        <input matInput placeholder="Posizione" [(ngModel)]="currentItem.position" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <mat-label>Referente</mat-label>
        <input matInput placeholder="Referente" [(ngModel)]="currentItem.owner" />
    </mat-form-field>
    <mat-form-field class="field field__phone">
        <mat-label>Telefono 1</mat-label>

        <input matInput placeholder="Telefono 1" [(ngModel)]="currentItem.ownerphone" />
    </mat-form-field>
    <mat-form-field class="field field__phone">
        <mat-label>Telefono 2</mat-label>

        <input matInput placeholder="Telefono 2" [(ngModel)]="currentItem.ownerphone2" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <mat-label>Email</mat-label>

        <input matInput placeholder="Email" [(ngModel)]="currentItem.owneremail" />
    </mat-form-field>
    <mat-form-field class="field-full-width">
        <mat-label>Note</mat-label>

        <input matInput placeholder="Note" [(ngModel)]="currentItem.note" />
    </mat-form-field>
    <div>
        <mat-form-field>
            <mat-label>Codice</mat-label>

            <input matInput placeholder="Codice" [(ngModel)]="currentItem.code" />
        </mat-form-field>
        <button mat-icon-button (click)="reCode()">
            <mat-icon class="mat-18">restore_page</mat-icon>
        </button>
    </div>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="saveData()">SALVA</button>
    <button mat-raised-button [disabled]="!validate()" class="ideb" (click)="close()">ANNULLA</button>
</mat-dialog-actions>
